import { useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Layout,
  Link,
  Card,
  Form,
  FormLayout,
  Button,
  Stack,
  Heading,
  Badge,
} from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";

import { ErrorBanner, Input } from "~/components";
import { IntegrationStatus, IntegrationType } from "~/graphql/sdk";
import { useSdk } from "~/hooks";

const schema = Yup.object().shape({
  klaviyoListId: Yup.string().required(),
});

export const Klaviyo = ({ data, id, deactivate }) => {
  const queryClient = useQueryClient();
  const sdk = useSdk();

  const [error, setError] = useState<any>();

  const klaviyoListId = useMemo(
    () => data?.integrationMetadata?.klaviyo?.listId,
    [data]
  );

  const { data: klaviyoIntegration } = useQuery(
    ["klaviyoIntegration"],
    () =>
      sdk
        .integrations({ filter: { type: { eq: IntegrationType.Klaviyo } } })
        .then((res) => res.integrations?.edges?.[0]?.node),
    { suspense: true }
  );

  const isKlaviyoActive = useMemo(
    () => klaviyoIntegration?.status === IntegrationStatus.Connected,
    [klaviyoIntegration]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      klaviyoListId: klaviyoListId || "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      await sdk.updateOneContainer({
        input: {
          id,
          update: {
            integrationMetadata: {
              ...(data?.integrationMetadata || {}),
              klaviyo: { listId: values.klaviyoListId },
            },
          },
        },
      });

      queryClient.invalidateQueries("flowContainer");
      setError(null);
      reset({ klaviyoListId: values.klaviyoListId });
      window?.top?.frames?.["app-iframe"]?.postMessage(
        {
          action: "toast",
          content: "Saved successfully",
        },
        "*"
      );
    } catch (e: any) {
      setError({
        message: e?.message,
        messages: e?.response?.errors?.map((err) => err?.message),
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Layout>
        <Layout.Section>
          {!!error && (
            <ErrorBanner
              title={`To connect ${name}, please fix the following error:`}
              messages={[
                `The provided public API key or the provided private API key is invalid.`,
              ]}
              error={error}
            />
          )}
        </Layout.Section>

        <Layout.AnnotatedSection
          title={
            <Stack alignment="center" spacing="tight">
              <div style={{ height: "24px" }}>
                <img
                  src="/logos/klaviyo.png"
                  alt="Klaviyo logo"
                  height="24px"
                  width="24px"
                />
              </div>
              <Heading>Klaviyo</Heading>
              {isKlaviyoActive && <Badge status="success">Connected</Badge>}
            </Stack>
          }
          description={
            <p>
              Enter the Klaviyo list ID of the list that users of this should be
              added to.
              <br />
              <Link url="https://www.trylantern.com/docs/l/klaviyo" external>
                Read more about setting up Klaviyo
              </Link>
            </p>
          }
        >
          <Card sectioned>
            <Stack vertical spacing="loose">
              <FormLayout>
                <Input
                  autoComplete="off"
                  name="klaviyoListId"
                  control={control}
                  label="List ID"
                  error={errors?.klaviyoListId?.message}
                  labelAction={{
                    content: "Where do I find this?",
                    url: "https://www.trylantern.com/docs/l/klaviyo",
                    external: true,
                  }}
                  placeholder="xxxxxx"
                  disabled={!isKlaviyoActive}
                />
              </FormLayout>

              <Stack distribution="trailing">
                <Button
                  onClick={async () => {
                    await onSubmit({ klaviyoListId: "" });
                    deactivate();
                  }}
                  disabled={false}
                  loading={isSubmitting}
                >
                  Deactivate
                </Button>
                <Button
                  submit
                  primary
                  disabled={!isDirty || !isKlaviyoActive}
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Form>
  );
};
