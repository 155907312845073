import { OptionsPane, DesignPane } from "~/components/sidePanes";
import { useNavParams } from "~/hooks";

import { SideMenu } from "../SideMenu";
import { useEffect, useState } from "react";

export const RightSideMenu = () => {
  const [{ tab }] = useNavParams();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const desktopStyles = {
    //3.5rem - TopBar height
    maxHeight: "calc(100vh - 3.5rem)",
    position: "fixed",
    right: "0",
    bottom: "0",
  };

  const mobileStyles = {
    width: "100%",
    height: "50vh",
    overflowY: "auto",
  };

  return (
    <SideMenu
      borderLeft="1px solid #EBEAEA"
      topStyles={isMobile ? mobileStyles : desktopStyles}
    >
      {tab === 0 && <OptionsPane />}

      {tab === 1 && <DesignPane />}
    </SideMenu>
  );
};
