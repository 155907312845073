import { useCallback, useState } from "react";
import {
  Button,
  Icon,
  IconSource,
  Popover,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import { AffiliateIcon } from "@shopify/polaris-icons";
import { Button as UIButton, Text } from "@storyofams/react-ui";
import {
  getBezierPath,
  getEdgeCenter,
  getMarkerEnd,
} from "react-flow-renderer";

const foreignObjectSize = 40;

export const Edge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) => {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />

      {!data.isDefault && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <div>
            <Popover
              active={popoverActive}
              activator={
                <UIButton
                  variant="link"
                  onClick={togglePopoverActive}
                  pointerEvents="all"
                >
                  <Icon source={AffiliateIcon as IconSource} color="base" />
                </UIButton>
              }
              onClose={togglePopoverActive}
              hideOnPrint
              ariaHaspopup="dialog"
              preferredAlignment="left"
              fluidContent
            >
              <Popover.Section>
                <Stack spacing="tight">
                  <Icon source={AffiliateIcon as IconSource} color="base" />
                  <TextStyle>Logic jump</TextStyle>
                </Stack>
              </Popover.Section>

              <Popover.Section>
                <Stack vertical spacing="loose">
                  <Text minWidth="200px">{data.popoverText}</Text>

                  <Stack distribution="trailing">
                    <Button onClick={data.toggleActive}>Edit</Button>
                  </Stack>
                </Stack>
              </Popover.Section>
            </Popover>
          </div>
        </foreignObject>
      )}
    </>
  );
};
