import { useState, useEffect } from "react";
import {
  Modal,
  Banner,
  Link,
  Stack,
  TextStyle,
  Checkbox,
  TextField,
} from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";
import styled from "styled-components";

const typesWithAnswers = [
  "SIMPLE_MULTI",
  "IMAGE_MULTI",
  "EMOJI_MULTI",
  "SIMPLE",
  "IMAGE",
  "EMOJI",
];

const MatchAnswersModal = ({
  open,
  onClose,
  nodes,
  addAnswers,
  variantNumber,
  answers,
  weights,
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState([] as any[]);
  const [selectedAnswersWeight, setSelectedAnswersWeight] = useState({});

  useEffect(() => {
    setSelectedAnswers(answers);
    setSelectedAnswersWeight(weights);
  }, [answers, weights]);

  const hasWelcomeScreen = !!(nodes?.[0]?.type === "WELCOME");

  const totalAvailableAnswers = nodes?.reduce((acc, node) => {
    return acc + (node?.options?.length ?? 0);
  }, 0);

  const numbersOfSelectedAnswers = selectedAnswers?.length;

  const allAnswersSelected = totalAvailableAnswers === numbersOfSelectedAnswers;

  const selectAllAnswers = () => {
    const allAnswers =
      nodes?.reduce((acc, node, idx) => {
        const questionNumber = idx + (hasWelcomeScreen ? 0 : 1);
        if (typesWithAnswers.includes(node?.type) && node?.options?.length) {
          return [
            ...acc,
            ...node.options.map((option) => ({
              id: option?.id,
              label: `Q${questionNumber}. ${option?.label ?? ""}`,
            })),
          ];
        } else {
          return acc;
        }
      }, []) ?? [];

    setSelectedAnswers(allAnswers);
  };

  const closeModal = () => {
    setSelectedAnswers([]);
    setSelectedAnswersWeight({});
    onClose();
  };

  const saveAnswers = () => {
    addAnswers(selectedAnswers, selectedAnswersWeight);
    closeModal();
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={`Match Answers for Variant ${variantNumber}`}
      primaryAction={{
        content: selectedAnswers?.length
          ? "Add Matching Answers"
          : "Save Matching Answers",
        onAction: saveAnswers,
      }}
      secondaryActions={[
        {
          content: "Back",
          onAction: closeModal,
        },
      ]}
      large
    >
      <Modal.Section>
        <Stack vertical spacing="loose">
          <Banner
            status="info"
            title="About Answer Weight (optional & advanced)"
          >
            <p>
              All answers have a default weight of 100. Optionally, the weight
              can be changed to any number between -1000 and 1000. If you want
              certain answers to carry more weight, to be a stronger vote for
              the text variant, then increase the value.
            </p>
            <br />
            <p>
              <i>
                Tip: you can use negative values to make sure that the text
                variant is not displayed if an answer is selected.
              </i>
            </p>
            <br />
            <p>
              For more details watch this{" "}
              <Link external url="https://youtu.be/HlHj5itBYRo">
                short video explanation
              </Link>
              .
            </p>
          </Banner>
          <AnswerContainer isChecked={allAnswersSelected} weight={1}>
            <Checkbox
              label={`${numbersOfSelectedAnswers} answers selected`}
              checked={allAnswersSelected}
              onChange={() => {
                if (allAnswersSelected) {
                  setSelectedAnswers([]);
                } else {
                  selectAllAnswers();
                }
              }}
            />
          </AnswerContainer>
          {nodes?.map((node, idx) => {
            const questionNumber = idx + (hasWelcomeScreen ? 0 : 1);
            if (
              typesWithAnswers.includes(node?.type) &&
              node?.options?.length
            ) {
              return (
                <Box mt={2} mb={2} key={node?.id}>
                  <Stack vertical>
                    <TextStyle variation="strong">
                      {`Q${questionNumber}. ${node?.title}`}
                    </TextStyle>
                    {node?.options?.map((option) => {
                      return (
                        <AnswerContainer
                          key={option?.id}
                          isChecked={selectedAnswers?.some(
                            ({ id }) => id === option?.id
                          )}
                          weight={selectedAnswersWeight?.[option?.id] ?? 100}
                        >
                          <Checkbox
                            label={option?.label}
                            checked={selectedAnswers?.some(
                              ({ id }) => id === option?.id
                            )}
                            onChange={(v) => {
                              if (v) {
                                setSelectedAnswers([
                                  ...selectedAnswers,
                                  {
                                    id: option?.id,
                                    label: `Q${questionNumber}. ${
                                      option?.label ?? ""
                                    }`,
                                  },
                                ]);
                              } else {
                                setSelectedAnswers(
                                  selectedAnswers.filter(
                                    ({ id }) => id !== option?.id
                                  )
                                );
                              }
                            }}
                          />
                          <WeightContainer>
                            <span>Weight:</span>
                            <TextField
                              type="number"
                              min={-1000}
                              max={1000}
                              autoComplete="off"
                              label=""
                              placeholder="100"
                              value={
                                "" +
                                (selectedAnswersWeight?.[option?.id] ?? 100)
                              }
                              onChange={(v) => {
                                setSelectedAnswersWeight({
                                  ...selectedAnswersWeight,
                                  [option?.id]: v,
                                });
                              }}
                            />
                          </WeightContainer>
                        </AnswerContainer>
                      );
                    })}
                  </Stack>
                </Box>
              );
            } else return null;
          })}
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default MatchAnswersModal;

const AnswerContainer = styled.div<{ isChecked: boolean; weight: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.isChecked ? (props.weight > 0 ? "#a4f6a8" : "#f6a4a8") : "#f4f6f8"};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 100%;

  & .Polaris-Choice {
    flex-grow: 1;
  }
`;

const WeightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
