import { ReactNode, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Link,
  Form,
  Button,
  Stack,
  Heading,
  Badge,
  Modal,
} from "@shopify/polaris";
import { Control, DeepMap, FieldError, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { ErrorBanner } from "~/components";
import {
  IntegrationFragmentFragment,
  IntegrationStatus,
  IntegrationType,
} from "~/graphql/sdk";
import { useSdk } from "~/hooks";

export interface IntegrationModalProps {
  getDefaultValues?(integration?: IntegrationFragmentFragment): any;
  integration?: IntegrationFragmentFragment;
  isOpen: boolean;
  name: string;
  onClose(): void;
  renderInputs?(
    control: Control<any>,
    errors: DeepMap<any, FieldError>,
    integration?: IntegrationFragmentFragment
  ): ReactNode;
  schema?: Yup.ObjectSchema<any>;
  type: IntegrationType;
}

export const IntegrationModal = ({
  getDefaultValues,
  integration,
  isOpen,
  name,
  onClose,
  renderInputs,
  schema,
  type,
}: IntegrationModalProps) => {
  const queryClient = useQueryClient();
  const sdk = useSdk();

  const [error, setError] = useState<any>();
  const [isDisconnecting, setDisconnecting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    reset,
  } = useForm({
    defaultValues: getDefaultValues ? getDefaultValues(integration) : {},
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const onSubmit = async (values) => {
    try {
      let result;

      if (integration?.id) {
        result = await sdk.updateOneIntegration({
          input: {
            id: integration.id,
            update: values,
          },
        });
      } else {
        result = await sdk.createOneIntegration({
          input: {
            integration: {
              ...values,
              type,
            },
          },
        });
      }

      reset(getDefaultValues ? getDefaultValues(result) : {});
      queryClient.invalidateQueries(["integrations"]);
      setError(null);
      window?.top?.frames?.["app-iframe"]?.postMessage(
        {
          action: "toast",
          content: integration?.id
            ? "Saved successfully"
            : `Successfully connected ${name}`,
        },
        "*"
      );
      onClose();
    } catch (e: any) {
      setError({
        message: e?.message,
        messages: e?.response?.errors?.map((err) => err?.message),
      });
    }
  };

  const onDisconnect = async () => {
    if (isDisconnecting || !integration?.id) {
      return;
    }

    setDisconnecting(true);

    try {
      await sdk.deleteOneIntegration({
        input: {
          id: integration.id,
        },
      });

      reset(getDefaultValues ? getDefaultValues() : {});
      queryClient.invalidateQueries(["integrations"]);
      window?.top?.frames?.["app-iframe"]?.postMessage(
        {
          action: "toast",
          content: `Disconnected ${name}`,
        },
        "*"
      );
      onClose();
    } catch (e: any) {
      window?.top?.frames?.["app-iframe"]?.postMessage(
        {
          action: "toast",
          content: `Error disconnecting ${name}${
            e?.message ? `: ${e.message}` : ""
          }`,
        },
        "*"
      );
    }

    setError(null);
    setDisconnecting(false);
  };

  return (
    <Modal
      sectioned
      open={isOpen}
      onClose={onClose}
      title={
        <Stack spacing="tight" alignment="center">
          <Heading>{name}</Heading>
          {!!integration?.id && (
            <Badge
              status={
                integration.status === IntegrationStatus.Connected
                  ? "success"
                  : "critical"
              }
            >
              {integration.status === IntegrationStatus.Connected
                ? "Connected"
                : "Invalid"}
            </Badge>
          )}
        </Stack>
      }
      footer={
        <Stack distribution="equalSpacing">
          <Button
            destructive
            disabled={!integration?.id || isSubmitting}
            loading={isDisconnecting}
            onClick={onDisconnect}
          >
            Disconnect
          </Button>

          {!!renderInputs && (
            <Button
              submit
              primary
              disabled={!isDirty || isDisconnecting}
              loading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {!integration?.id ? "Connect" : "Update"}
            </Button>
          )}
        </Stack>
      }
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Stack vertical spacing="loose">
          {!!error && (
            <ErrorBanner
              title={`To connect ${name}, please fix the following error:`}
              messages={[`The API key is invalid.`]}
              error={error}
            />
          )}

          <p>
            {!integration?.id
              ? `Enter your ${name} credentials to enable this integration.`
              : `You have enabled the ${name} integration.`}
            <br />
            <Link
              url={`https://www.trylantern.com/docs/l/${name.toLowerCase()}`}
              external
            >
              Read more about setting up {name}
            </Link>
          </p>

          {!!renderInputs && renderInputs(control, errors, integration)}
        </Stack>
      </Form>
    </Modal>
  );
};
