import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import equal from "fast-deep-equal";
import { debounce, find } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import validator from "validator";
import { ContainerQuery, FlowNode, FlowStatus } from "~/graphql/sdk";
import { useSdk, useNavParams } from "~/hooks";

interface ProviderProps {
  children: ReactNode;
}

export type FlowNodeState = FlowNode & { isModified?: boolean };

type Status = "initial" | "saving" | "saved" | "error";

interface ContextProps {
  data?: ContainerQuery["flowContainer"] | null;
  error?: any;
  flowNode?: FlowNodeState;
  publishFlow({ validateOnly }: { validateOnly: boolean }): Promise<boolean>;
  updateFlowNode(id: string, data: Partial<FlowNode>): void;
  status: Status;
  setStatus(status: Status): void;
  lastUpdate: any;
}

export const FlowContext = createContext<ContextProps>({} as unknown as any);

const withoutImages = (node: any) => ({
  ...node,
  options: node?.options?.map(({ image, nextNode, nextAction, ...option }) => ({
    ...option,
  })),
});

export const FlowProvider = ({ children }: ProviderProps) => {
  const queryClient = useQueryClient();
  const [{ question }] = useNavParams();
  const { id } = useParams<{ id: string }>();
  const sdk = useSdk();

  const flowNodesRef = useRef<Record<number, FlowNodeState>>({});
  const prevUpdate = useRef<Partial<FlowNode>>();
  const [status, setStatus] = useState<Status>("initial");
  const [lastUpdate, setLastUpdate] = useState<any>(null);

  const { data: initialData, error } = useQuery(
    ["container", { id }],
    () =>
      sdk
        .container({
          id,
          flowFilter: {
            status: {
              in: [FlowStatus.Active, FlowStatus.Draft],
            },
          },
        })
        .then((res) => res.flowContainer),
    {
      suspense: true,
    }
  );

  // sort flows such that draft flows are first from initialData to data
  const data = useMemo(() => {
    if (!initialData) {
      return initialData;
    }

    const sortedFlows = initialData.flows?.sort((a, b) => {
      if (a.status === FlowStatus.Draft) {
        return -1;
      }

      if (b.status === FlowStatus.Draft) {
        return 1;
      }

      return 0;
    });

    return {
      ...initialData,
      flows: sortedFlows,
    };
  }, [initialData]);

  const currentFlow = useMemo(() => data?.flows?.[0], [data]);

  if (!flowNodesRef.current?.[question]) {
    flowNodesRef.current[question] = currentFlow?.nodes?.[question] as FlowNode;
  }

  useEffect(() => {
    if (
      !flowNodesRef.current?.[question] ||
      flowNodesRef.current?.[question]?.id !==
        currentFlow?.nodes?.[question]?.id ||
      !flowNodesRef.current?.[question].isModified
    ) {
      flowNodesRef.current[question] = currentFlow?.nodes?.[
        question
      ] as FlowNode;
    }
  }, [currentFlow, question]);

  const createDraftFlow = async () => {
    try {
      await sdk.createOneFlow({
        input: {
          flow: {
            containerId: id,
          },
        },
      });

      queryClient.invalidateQueries(["container", { id }]);
    } catch (e) {
      // pass
    }
  };

  useEffect(() => {
    if (
      currentFlow &&
      [FlowStatus.Active, FlowStatus.Inactive].includes(currentFlow.status)
    ) {
      createDraftFlow();
    }
  }, [currentFlow]);

  const publishFlow = async ({
    validateOnly = false,
  }: {
    validateOnly: boolean;
  }) => {
    if (!data?.id || !data?.flows?.[data?.flows?.length - 1]?.id) {
      return false;
    }

    try {
      await sdk.activateFlow({
        input: {
          flowId: data.flows[0].id,
          validateOnly: validateOnly,
        },
      });

      if (!validateOnly) {
        createDraftFlow();
      }

      return true;
    } catch (e) {
      throw e;
    }
  };

  const updateApi = async (flowNodeId, update, questionIndex) => {
    try {
      setLastUpdate(update);

      const result = await sdk.updateOneFlowNode({
        input: {
          id: flowNodeId,
          update,
        },
      });

      flowNodesRef.current[questionIndex].isModified = false;

      queryClient.setQueryData(["container", { id }], (old: any) => {
        const nodes = old.flows?.[0]?.nodes?.map((node) => {
          if (node?.id === flowNodeId) {
            return result.updateOneFlowNode;
          }

          return node;
        });

        return {
          ...old,
          flows: [
            {
              ...old.flows?.[0],
              nodes,
            },
            ...old?.flows?.slice(1),
          ],
        };
      });

      setStatus("saved");
      window.onbeforeunload = undefined as any;
    } catch (e) {
      console.error(e);

      setStatus("error");
    }
  };

  const debouncedUpdate = useCallback(
    debounce(updateApi, 400, { trailing: true }),
    []
  );

  const updateFlowNode = (flowNodeId: string, update: Partial<FlowNode>) => {
    delete (update as any).isModified;

    update?.options?.forEach((option) => {
      if ((option as any)?.id === "new") {
        delete (option as any).id;
        delete (option as any).productIds;
        delete (option as any).variantIds;
        delete (option as any).collectionIds;
      }

      if ((option as any)?.fieldKey) {
        delete (option as any).fieldKey;
      }
    });

    if (
      flowNodeId === currentFlow?.nodes?.[question]?.id &&
      (!flowNodesRef.current?.[question] ||
        (update?.layout &&
          update.layout !== flowNodesRef.current[question].layout) ||
        !equal(
          withoutImages({
            title: flowNodesRef.current[question].title,
            description: flowNodesRef.current[question].description,
            options: flowNodesRef.current[question].options,
          }),
          withoutImages(update)
        )) &&
      (!prevUpdate.current || !equal(prevUpdate.current, withoutImages(update)))
    ) {
      flowNodesRef.current[question] = {
        ...flowNodesRef.current[question],
        ...update,
        options: update.options?.map((option) => {
          if (option.id) {
            const image = find(currentFlow?.nodes?.[question]?.options, {
              id: option.id,
            })?.image;

            if (image) {
              return {
                ...option,
                image,
              };
            }
          }

          return option;
        }),
        isModified: true,
      } as FlowNode;

      prevUpdate.current = withoutImages(update);

      setStatus("saving");
      window.onbeforeunload = () => true;

      debouncedUpdate(
        flowNodeId,
        withoutImages({
          title: flowNodesRef.current[question].title,
          description: flowNodesRef.current[question].description,
          options: update.options,
        }),
        question
      );
    }
  };

  //Due to issues with some colors being stored without a prefix of "#" we need to update it
  //This can be remove in the future
  let newData = data;

  if (newData && newData?.flows?.[0].primaryColor?.[0] !== "#") {
    const newPrimaryColor = "#" + newData?.flows?.[0].primaryColor;

    //Confirm that newPrimaryColor is still a hexColor with an added prefix
    if (validator.isHexColor(newPrimaryColor)) {
      newData.flows[0].primaryColor = newPrimaryColor;
    }
  }

  return (
    <FlowContext.Provider
      value={{
        data: newData,
        error,
        flowNode: flowNodesRef.current[question],
        publishFlow,
        updateFlowNode,
        status,
        setStatus,
        lastUpdate,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};
