import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Layout,
  Card,
  DropZone,
  Form,
  FormLayout,
  Button,
  Stack,
  TextField,
  TextStyle,
  Tooltip,
  Icon,
} from "@shopify/polaris";
import { Controller, useForm } from "react-hook-form";
import slugifyFn from "slugify";
import * as Yup from "yup";

import { ErrorBanner, Input } from "~/components";
import { FileUnion } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { getErrorLength, getFileUrl, useToast } from "~/lib";
import { QuestionCircleIcon } from "@shopify/polaris-icons";

const schema = Yup.object().shape({
  name: Yup.string().required(),
  slug: Yup.string().required(),
  seoTitle: Yup.string(),
  seoDescription: Yup.string(),
  favicon: Yup.string(),
  trackingCode: Yup.string(),
  bodyTrackingCode: Yup.string(),
  finishLaterRedirect: Yup.string(),
});

const slugify = (value: string) =>
  slugifyFn(value, { lower: true, strict: true });

interface FlowGeneralSettingsProps {
  allFields?: boolean;
  defaultValues?: any;
  error?: any;
  id?: string;
  ogImage?: FileUnion;
  onSubmit(values: any): void;
  submitText?: string;
}

export const FlowGeneralSettings = ({
  allFields,
  defaultValues,
  error,
  id,
  ogImage,
  onSubmit,
  submitText,
}: FlowGeneralSettingsProps) => {
  const sdk = useSdk();
  const toast = useToast();

  const [isBusy, setBusy] = useState(false);
  const [file, setFile] = useState<any>();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const uploadFile = async (file) => {
    if (isBusy) {
      return;
    }

    setBusy(true);

    try {
      await sdk.updateFlowContainerOgImage({
        input: {
          id,
          ogImage: file,
        },
      });

      setFile(window.URL.createObjectURL(file));
    } catch (e) {
      toast({ content: "Error uploading image", error: true });
    }

    setBusy(false);
  };

  const handleDrop = (_droppedFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.[0]) {
      toast({
        content: `"${rejectedFiles?.[0].name}" is not supported. File type must be .jpg or .png.`,
        error: true,
      });
    } else if (acceptedFiles?.[0]) {
      uploadFile(acceptedFiles[0]);
    }
  };

  useEffect(
    () => () => {
      if (file) {
        window.URL.revokeObjectURL(file);
      }
    },
    []
  );

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!!error && (
          <ErrorBanner
            title={`To save this flow, ${getErrorLength(error)} ${
              getErrorLength(error) > 1 ? "changes need" : "change needs"
            } to be made:`}
            error={error}
          />
        )}
        <div
          style={{
            position: "absolute",
            bottom: "100%",
            right: "0%",
            transform: "translateY(-16px)",
          }}
        >
          <Stack alignment="trailing" vertical>
            <Button submit primary disabled={!isDirty} loading={isSubmitting}>
              {submitText || "Save"}
            </Button>
          </Stack>
        </div>
        <Card title="General Settings" sectioned>
          <FormLayout>
            <Controller
              name="name"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  onChange={(value) => {
                    field.onChange(value);
                    setValue("slug", slugify(value), {
                      shouldValidate: true,
                    });
                  }}
                  label="Quiz Flow Name"
                  error={errors?.name?.message}
                />
              )}
            />
            <Controller
              name="slug"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  autoComplete="off"
                  onChange={(value) => {
                    field.onChange(slugify(value));
                  }}
                  label="Quiz Flow Slug"
                  helpText="This will be part of the path (URL) where this finder can be accessed"
                  error={errors?.slug?.message}
                />
              )}
            />
            <Input
              autoComplete="off"
              name="finishLaterRedirect"
              control={control}
              label="Finish Later Redirect URL"
              error={errors?.finishLaterRedirect?.message}
              placeholder="leave blank to return to the original store page"
            />
          </FormLayout>
        </Card>

        <Card title="Tracking Code" sectioned>
          <FormLayout>
            <Input
              autoComplete="off"
              name="trackingCode"
              control={control}
              label="Head Tracking Code (code will be added to the <head> tag)"
              error={errors?.trackingCode?.message}
              placeholder="copy-paste your tracking code here"
              multiline={6}
            />
            <Input
              autoComplete="off"
              name="bodyTrackingCode"
              control={control}
              label="Body Tracking Code (code will be added to the <body> tag)"
              error={errors?.bodyTrackingCode?.message}
              placeholder="copy-paste your tracking code here"
              multiline={6}
            />
          </FormLayout>
        </Card>
        <Card title="SEO Settings" sectioned>
          <FormLayout>
            <Input
              autoComplete="off"
              name="seoTitle"
              control={control}
              label="Meta title"
              error={errors?.seoTitle?.message}
              placeholder="Meta title"
            />

            <Input
              autoComplete="off"
              name="seoDescription"
              control={control}
              label="Meta description"
              error={errors?.seoDescription?.message}
              placeholder="Meta description"
            />

            <Input
              autoComplete="off"
              name="favicon"
              control={control}
              label={
                <Stack alignment="center" spacing="tight">
                  <span>Favicon</span>

                  <Tooltip
                    content="Please make sure the format of this link is a public URL, rather than a path to where the image is stored on your local device."
                    dismissOnMouseOut
                  >
                    <Icon source={QuestionCircleIcon as any} color="base" />
                  </Tooltip>
                </Stack>
              }
              error={errors?.favicon?.message}
              placeholder="https://example.com/favicon.png"
            />

            <Stack vertical spacing="tight">
              <DropZone
                label="Meta image"
                accept="image/*"
                type="image"
                onDrop={handleDrop}
                allowMultiple={false}
                disabled={isBusy}
              >
                {file || ogImage ? (
                  <img
                    src={file || getFileUrl(ogImage)}
                    alt="Meta"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                ) : (
                  <DropZone.FileUpload
                    actionHint="or drop image to upload"
                    actionTitle="Add image"
                  />
                )}
              </DropZone>

              <TextStyle variation="subdued">Dimensions 1200 x 630</TextStyle>
            </Stack>
          </FormLayout>
        </Card>
        <Layout.Section>
          <Stack alignment="trailing" vertical>
            <Button submit primary disabled={!isDirty} loading={isSubmitting}>
              {submitText || "Save"}
            </Button>
          </Stack>
        </Layout.Section>
      </Form>
    </div>
  );
};
