import { forwardRef, ReactNode } from "react";
import { Box, Icon, Spinner, SystemProps } from "@storyofams/react-ui";
import { rgba, readableColor } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  borderRadius,
  typography,
  ResponsiveValue,
  variant,
} from "styled-system";
import { useIsRtl } from "~/hooks";
import { ReactComponent as arrowRight } from "../Icon/library/arrow-right.svg";
import { ReactComponent as plusIcon } from "../Icon/library/plus.svg";

const _defaultElement = "button";

const sizes = {
  small: {
    borderRadius: "xs",
    py: 2,
    px: 3,
    fontSize: 2,
    lineHeight: "19px",
  },
  large: {
    borderRadius: "md",
    py: 2.5,
    px: 7,
    fontSize: 2.25,
    lineHeight: "22px",
  },
};

const variants = {
  primary: {
    bg: "primary",
    color: "white",
  },
  secondary: {
    bg: "white",
    color: "black90",
  },
  plain: {
    bg: "transparent",
    color: "primary",
  },
};

type ButtonProps = Omit<SystemProps, "size"> & {
  arrow?: boolean;
  plus?: boolean;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?(): void;
  primaryColor?: string;
  /** @default large */
  size?: ResponsiveValue<keyof typeof sizes>;
  to?: string;
  /** @default primary */
  variant?: ResponsiveValue<keyof typeof variants>;
  style?: any;
};

const Hover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 0;
  transition: background-color 0.18s ease-in-out, box-shadow 0.18s ease-in-out;
`;

const StyledIcon = styled(Icon)``;

const StyledButton = styled(Box)<
  Pick<ButtonProps, "primaryColor" | "variant"> & {
    foregroundColor?: string;
    isRtl?: boolean;
  }
>`
  position: relative;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  text-align: center;
  text-decoration: none;
  border: 0;
  transition: background-color 0.18s ease-in-out, box-shadow 0.18s,
    border-color 0.18s ease-in-out, color 0.18s ease-in-out,
    opacity 0.18s ease-in-out;
  overflow: hidden;
  user-select: none;
  font-weight: bold;

  ${StyledIcon} {
    width: 0;
    transition: width 0.25s ease-out;
  }

  ${(p) =>
    p.isRtl &&
    css`
      ${StyledIcon} {
        transform: rotate(180deg);
      }
    `}

  &:hover {
    ${Hover} {
      background-color: ${(p) =>
        p.theme.colors[p.variant === "secondary" ? "black10" : "black20"]};
    }

    ${StyledIcon} {
      width: 22px;
    }
  }

  &:active,
  &:focus {
    box-shadow: 0px 0px 0px 3px
      ${(p) => rgba(p.primaryColor || p.theme.colors.primary, 0.4)};

    ${Hover} {
      background-color: transparent;
    }
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;

    ${StyledIcon} {
      width: 0;
    }
  }

  &&[data-is-loading] {
    cursor: wait;
  }

  ${variant({ variants })}
  ${variant({ prop: "size", variants: sizes })}
  ${borderRadius}
  ${typography}

  ${(p) =>
    !!p.primaryColor &&
    css`
      background-color: ${p.primaryColor};
      color: ${p.foregroundColor};
    `}
`;

export const Button = forwardRef(
  ({ arrow, plus, style = {}, ...props }: ButtonProps, ref) => {
    const isRtl = useIsRtl();

    const foregroundColor = props.primaryColor
      ? readableColor(props.primaryColor, "rgba(0, 0, 0, 0.9)", "#fff")
      : "white";

    if (props?.isLoading) {
      return (
        <StyledButton
          as={_defaultElement}
          variant="primary"
          size="large"
          foregroundColor={foregroundColor}
          {...props}
          /** @ts-ignore */
          ref={ref}
          position="relative"
          data-is-loading
          aria-disabled
          disabled
          isRtl={isRtl}
        >
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Spinner />
          </Box>
          <Box display="flex" color="transparent">
            {props?.children}
          </Box>
        </StyledButton>
      );
    }

    const content = (
      <StyledButton
        as={_defaultElement}
        variant="primary"
        size="large"
        foregroundColor={foregroundColor}
        {...props}
        /** @ts-ignore */
        ref={ref}
        isRtl={isRtl}
        style={{ ...style }}
      >
        <Hover />
        <Box display="flex" zIndex={1}>
          {props.children}
          {!!arrow && (
            <StyledIcon icon={arrowRight} css={{ marginInlineStart: "8px" }} />
          )}
          {!!plus && (
            <StyledIcon icon={plusIcon} css={{ marginInlineStart: "8px" }} />
          )}
        </Box>
      </StyledButton>
    );

    if (props?.to) {
      return <Link to={props.to}>{content}</Link>;
    }

    return content;
  }
);
