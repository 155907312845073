import { useCallback, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  ChoiceList,
  IconSource,
  Modal,
  Stack,
  TextStyle,
} from "@shopify/polaris";
import { Box } from "@storyofams/react-ui";
import produce from "immer";
import qs from "query-string";
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Flow, FlowNodeLayout, FlowNodeType } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { useBillingWrapper, useToast } from "~/lib";
import { ErrorBanner } from "~/components";
import { OPTIONS } from "../sidePanes/OptionsPane/QuestionOptions";
import {
  TransactionIcon,
  FormsIcon,
  StatusActiveIcon,
  ImageWithTextOverlayIcon,
  SmileyHappyIcon,
  TextBlockIcon,
  CircleChevronLeftIcon,
  ListBulletedIcon,
  EmailIcon,
} from "@shopify/polaris-icons";

interface QuestionTypeModalProps {
  active: boolean;
  currentFlow: Flow;
  klaviyoListId?: string;
  questionIndex?: number;
  setActive(active: (active: boolean) => boolean): void;
  type: "new" | "edit";
}

const schema = Yup.object().shape({
  klaviyoListId: Yup.string(),
});

export const QuestionTypeModal = ({
  active,
  currentFlow,
  questionIndex,
  setActive,
  type: modalType,
}: QuestionTypeModalProps) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const sdk = useSdk();
  const toast = useToast();
  const { pathname, search } = useLocation();
  const billingWrapper = useBillingWrapper({ freeMultiQuestionAccess: true });
  const billingWrapperAdvancedPages = useBillingWrapper({});

  const history = useHistory();
  const params = useMemo(() => qs.parse(search), [search]);

  const [error, setError] = useState<any>(null);
  const [isBusy, setBusy] = useState(false);

  // const { data: store } = useQuery(["currentStore"], () =>
  //   sdk.currentStore().then((res) => res.currentStore)
  // );

  // const [multiQuestionModal, setMultiQuestionModal] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      type: FlowNodeType.Simple,
    },
    resolver: yupResolver(schema),
  });

  const toggleActive = useCallback(
    (newValue?) => {
      setActive((active) =>
        typeof newValue === "boolean" ? newValue : !active
      );

      setPageType("");

      if (active) {
        setError(null);
      }
    },
    [active]
  );

  const createNewQuestion = async ({ values, type, isMulti }) => {
    setBusy(true);

    try {
      let result;

      if (modalType === "new") {
        if (values.klaviyoListId) {
          await sdk.updateOneContainer({
            input: {
              id,
              update: {
                integrationMetadata: {
                  klaviyo: { listId: values.klaviyoListId },
                },
              },
            },
          });
        }

        result = (
          await sdk.createOneFlowNode({
            input: {
              flowNode: {
                flowId: currentFlow.id,
                layout: FlowNodeLayout.Simple,
                isRequired: type !== FlowNodeType.Transition ? true : false,
                maxLength: type !== FlowNodeType.Transition ? "" : "3",
                type,
                nextQuestionOnSelection: !isMulti,
                options: [],
              },
            },
          })
        ).createOneFlowNode;
      } else {
        result = (
          await sdk.updateOneFlowNode({
            input: {
              id: currentFlow?.nodes?.[questionIndex as number]?.id,
              update: {
                type,
              },
            },
          })
        ).updateOneFlowNode;
      }

      const nodes = currentFlow.nodes.map((node) => node.id);
      if (result?.createOneFlowNode?.id) {
        nodes.push(result.createOneFlowNode.id);
      }

      await sdk.updateFlowNodesOrder({
        input: {
          flowId: currentFlow.id,
          order: nodes,
        },
      });

      setError(null);
      toast({
        content:
          modalType === "new"
            ? type === FlowNodeType.Welcome
              ? "Welcome screen added"
              : type === FlowNodeType.Email
              ? "Email step added"
              : type === FlowNodeType.Transition
              ? "Transition screen added"
              : "Question added"
            : "Question updated",
      });

      queryClient.setQueryData(["container", { id }], (old: any) =>
        produce(old, (draft) => {
          if (type === FlowNodeType.Welcome) {
            // Add welcome at start
            draft.flows?.[0]?.nodes?.unshift(result);
          } else if (modalType === "new") {
            if (
              old.flows?.[0]?.nodes?.[old.flows?.[0]?.nodes?.length - 1]
                ?.type === FlowNodeType.Email
            ) {
              // Add before email at end
              draft.flows?.[0]?.nodes?.splice(
                old.flows?.[0]?.nodes?.length - 1,
                0,
                result
              );
            } else {
              draft.flows?.[0]?.nodes?.push(result);
            }
          } else {
            // Update existing
            const index = draft.flows?.[0]?.nodes?.findIndex(
              (node) => node?.id === result.id
            );

            if (index !== -1) {
              draft.flows[0].nodes[index] = result;
            }
          }
        })
      );

      history.replace({
        pathname,
        search: qs.stringify({
          ...params,
          main: "0",
          question:
            modalType === "new"
              ? type === FlowNodeType.Welcome
                ? 0
                : currentFlow?.nodes?.[currentFlow?.nodes?.length - 1]?.type ===
                  FlowNodeType.Email
                ? currentFlow?.nodes?.length - 1
                : currentFlow?.nodes?.length
              : (questionIndex as number),
        }),
      });
      toggleActive(false);
    } catch (e: any) {
      setError({
        message: e?.message,
        messages: e?.response?.errors?.map((err) => err?.message),
      });
    }

    setBusy(false);
  };

  const onSubmit = async ({ type, ...values }) => {
    if (
      isBusy ||
      !currentFlow ||
      (modalType === "edit" && typeof questionIndex === "undefined")
    ) {
      return;
    }

    const isMulti =
      type === FlowNodeType.EmojiMulti ||
      type === FlowNodeType.ImageMulti ||
      type === FlowNodeType.SimpleMulti;

    const isAdvancedPage =
      type === FlowNodeType.InputOneLineText ||
      type === FlowNodeType.InputMultiLineText ||
      type === FlowNodeType.Transition;

    const isEmail = type === FlowNodeType.Email;

    // if an email step already exists, don't allow another one
    if (isEmail && currentFlow?.nodes?.some((node) => node.type === type)) {
      setError({
        messages: ["You can only have one email step in your quiz."],
      });
      return;
    }

    if (isAdvancedPage) {
      toggleActive();
      await billingWrapperAdvancedPages(createNewQuestion)({
        values,
        type,
        isMulti,
      });
      return;
    }

    if (isMulti) {
      toggleActive();
      await billingWrapper(createNewQuestion)({ values, type, isMulti });
      return;
    }

    await createNewQuestion({ values, type, isMulti });
  };

  useEffect(() => {
    if (modalType === "edit" && active && questionIndex) {
      reset({ type: currentFlow?.nodes?.[questionIndex]?.type });
    }
  }, [active]);

  if (modalType === "edit" && questionIndex === undefined) {
    return null;
  }

  const [pageType, setPageType] = useState<any>("");

  let modalTitle = "Add a New Page";
  if (modalType !== "new") {
    modalTitle = `Edit page type for question ${(questionIndex as number) + 1}`;
  }
  if (pageType === "choice") {
    modalTitle = "Choose a Question Type";
  }
  if (pageType === "input") {
    modalTitle = "Choose an Input Type";
  }

  return (
    <>
      <Modal
        large
        open={active}
        onClose={toggleActive}
        title={modalTitle}
        primaryAction={
          pageType !== "" &&
          pageType !== "choice" &&
          pageType !== "input" &&
          pageType !== "transition"
            ? {
                content: modalType === "new" ? "Add Page" : "Save",
                onAction: handleSubmit(onSubmit),
                loading: isSubmitting,
              }
            : undefined
        }
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleActive,
          },
        ]}
      >
        <Box p={2}>
          <ErrorBanner
            title={
              modalType === "new"
                ? "Error adding page:"
                : "Error changing page type:"
            }
            error={error}
            messages={error?.messages}
          />

          <Controller
            name="type"
            control={control}
            render={({ field: { ref, ...field } }) => {
              if (pageType === "input") {
                return (
                  <>
                    <Button
                      onClick={() => {
                        setPageType("");
                      }}
                      icon={CircleChevronLeftIcon as IconSource}
                    >
                      Go Back
                    </Button>
                    <Stack alignment="center" distribution="center">
                      <div style={{ width: "300px" }}>
                        <Stack vertical alignment="fill" distribution="center">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pagetypes/singleLineInput.png`}
                            style={{ height: "200px", margin: "0 auto" }}
                          />
                          <Button
                            onClick={async () => {
                              await field.onChange(
                                FlowNodeType.InputOneLineText
                              );
                              handleSubmit(onSubmit)();
                            }}
                            primary
                            fullWidth
                            icon={FormsIcon as IconSource}
                          >
                            Single Line Text Field
                          </Button>

                          <div style={{ textAlign: "center" }}>
                            <TextStyle variation="subdued">
                              Suitable for short answers (one line).
                            </TextStyle>
                          </div>
                        </Stack>
                      </div>
                      <div style={{ width: "300px" }}>
                        <Stack vertical alignment="fill" distribution="center">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pagetypes/multiLineInput.png`}
                            style={{ height: "200px", margin: "0 auto" }}
                          />

                          <Button
                            onClick={async () => {
                              await field.onChange(
                                FlowNodeType.InputMultiLineText
                              );
                              handleSubmit(onSubmit)();
                            }}
                            primary
                            fullWidth
                            icon={ListBulletedIcon as IconSource}
                          >
                            Multi-Line Text Field
                          </Button>

                          <div style={{ textAlign: "center" }}>
                            <TextStyle variation="subdued">
                              Suitable for longer answers (multiple lines).
                            </TextStyle>
                          </div>
                        </Stack>
                      </div>
                    </Stack>
                  </>
                );
              }

              if (pageType === "choice") {
                return (
                  <>
                    <Button
                      onClick={() => {
                        setPageType("");
                      }}
                      icon={CircleChevronLeftIcon as IconSource}
                    >
                      Go Back
                    </Button>
                    <Stack alignment="center" distribution="center">
                      <div style={{ width: "300px" }}>
                        <Stack vertical alignment="fill" distribution="center">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pagetypes/singleChoice.png`}
                            style={{ height: "200px", margin: "0 auto" }}
                          />
                          <Button
                            onClick={async () => {
                              await field.onChange(FlowNodeType.Simple);
                              handleSubmit(onSubmit)();
                            }}
                            primary
                            fullWidth
                            icon={TextBlockIcon as IconSource}
                          >
                            Single Choice - Text Only
                          </Button>

                          <Button
                            onClick={async () => {
                              await field.onChange(FlowNodeType.Emoji);
                              handleSubmit(onSubmit)();
                            }}
                            primary
                            fullWidth
                            icon={SmileyHappyIcon as IconSource}
                          >
                            Single Choice - Text &amp; Emoji
                          </Button>

                          <Button
                            onClick={async () => {
                              await field.onChange(FlowNodeType.Image);
                              handleSubmit(onSubmit)();
                            }}
                            primary
                            fullWidth
                            icon={ImageWithTextOverlayIcon as IconSource}
                          >
                            Single Choice - Text &amp; Image
                          </Button>

                          <div style={{ textAlign: "center" }}>
                            <TextStyle variation="subdued">
                              Allow customers to select a single answer from a
                              set of choices.
                            </TextStyle>
                          </div>
                        </Stack>
                      </div>
                      <div style={{ width: "300px" }}>
                        <Stack vertical alignment="fill" distribution="center">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/pagetypes/multipleChoice.png`}
                            style={{ height: "200px", margin: "0 auto" }}
                          />
                          <Button
                            onClick={async () => {
                              await field.onChange(FlowNodeType.SimpleMulti);
                              handleSubmit(onSubmit)();
                            }}
                            primary
                            fullWidth
                            icon={TextBlockIcon as IconSource}
                          >
                            Multiple Choices - Text Only
                          </Button>

                          <Button
                            onClick={async () => {
                              await field.onChange(FlowNodeType.EmojiMulti);
                              handleSubmit(onSubmit)();
                            }}
                            primary
                            fullWidth
                            icon={SmileyHappyIcon as IconSource}
                          >
                            Multiple Choices - Text &amp; Emoji
                          </Button>

                          <Button
                            onClick={async () => {
                              await field.onChange(FlowNodeType.ImageMulti);
                              handleSubmit(onSubmit)();
                            }}
                            primary
                            fullWidth
                            icon={ImageWithTextOverlayIcon as IconSource}
                          >
                            Multiple Choices - Text &amp; Image
                          </Button>

                          <div style={{ textAlign: "center" }}>
                            <TextStyle variation="subdued">
                              Allow customers to choose multiple answers from a
                              set of choices.
                            </TextStyle>
                          </div>
                        </Stack>
                      </div>
                    </Stack>
                  </>
                );
              }

              if (pageType === "") {
                return (
                  <Stack alignment="center" distribution="center">
                    <div style={{ width: "300px" }}>
                      <Stack vertical alignment="fill" distribution="center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pagetypes/choiceQuestion.png`}
                          style={{ height: "200px", margin: "0 auto" }}
                        />
                        <Button
                          onClick={() => {
                            setPageType("choice");
                          }}
                          primary
                          fullWidth
                          icon={StatusActiveIcon as IconSource}
                        >
                          Choice Question
                        </Button>

                        <div style={{ textAlign: "center" }}>
                          <TextStyle variation="subdued">
                            Ask single/multiple choice questions to help refine
                            product recommendations.
                          </TextStyle>
                        </div>
                      </Stack>
                    </div>
                    <div style={{ width: "300px" }}>
                      <Stack vertical alignment="fill" distribution="center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pagetypes/inputQuestion.png`}
                          style={{ height: "200px", margin: "0 auto" }}
                        />
                        <Button
                          onClick={() => {
                            setPageType("input");
                          }}
                          primary
                          fullWidth
                          icon={FormsIcon as IconSource}
                        >
                          Customer Input
                        </Button>

                        <div style={{ textAlign: "center" }}>
                          <TextStyle variation="subdued">
                            Gather direct input and collect data from your
                            customers.
                          </TextStyle>
                        </div>
                      </Stack>
                    </div>
                    <div style={{ width: "300px" }}>
                      <Stack vertical alignment="fill" distribution="center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pagetypes/emailCapturePage.png`}
                          style={{ height: "200px", margin: "0 auto" }}
                        />
                        <Button
                          onClick={async () => {
                            await field.onChange(FlowNodeType.Email);
                            handleSubmit(onSubmit)();
                          }}
                          primary
                          fullWidth
                          icon={EmailIcon as IconSource}
                        >
                          Email Capture Page
                        </Button>

                        <div style={{ textAlign: "center" }}>
                          <TextStyle variation="subdued">
                            Collect your customers email address to send them a
                            copy of their results.
                          </TextStyle>
                        </div>
                      </Stack>
                    </div>
                    <div style={{ width: "300px" }}>
                      <Stack vertical alignment="fill" distribution="center">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/pagetypes/transitionScreen.png`}
                          style={{ height: "200px", margin: "0 auto" }}
                        />
                        <Button
                          onClick={async () => {
                            await field.onChange(FlowNodeType.Transition);
                            handleSubmit(onSubmit)();
                          }}
                          primary
                          fullWidth
                          icon={TransactionIcon as IconSource}
                        >
                          Transition Screen
                        </Button>

                        <div style={{ textAlign: "center" }}>
                          <TextStyle variation="subdued">
                            Add a transition screen between questions or before
                            revealing the results.
                          </TextStyle>
                        </div>
                      </Stack>
                    </div>
                  </Stack>
                );
              }

              return (
                <ChoiceList
                  title="Question type"
                  titleHidden
                  choices={OPTIONS}
                  selected={[field.value]}
                  {...field}
                  onChange={(value) => field.onChange(value?.[0])}
                  error={errors?.type?.message}
                />
              );
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
