import { Fragment, useMemo, useState } from "react";
import { Button, Heading, Link, Stack, TextContainer } from "@shopify/polaris";
import { useQuery } from "react-query";
import { IntegrationStatus, IntegrationType } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { IntegrationCard } from "./IntegrationCard";
import { KlaviyoModal, MailchimpModal } from "./modals";

enum Category {
  ALL = "All",
  MARKETING = "Marketing",
  REVIEWS = "Reviews",
  SUBSCRIPTIONS = "Subscriptions",
  AUTOMATIONS = "Automations",
}

const INTEGRATIONS = [
  {
    icon: (
      <div style={{ width: "34px", marginLeft: "-3px", marginRight: "3px" }}>
        <img src="/logos/klaviyo.png" alt="Klaviyo logo" width="40px" />
      </div>
    ),
    title: "Klaviyo",
    description:
      "Create personalized email campaigns based on customer responses.",
    url: "https://www.trylantern.com/docs/l/how-to-dynamically-tailor-klaviyo-emails",
    category: Category.MARKETING,
    type: IntegrationType.Klaviyo,
    Modal: KlaviyoModal,
    requiresConnection: true,
  },
  {
    icon: (
      <div style={{ width: "34px" }}>
        <img src="/logos/mailchimp.png" alt="Mailchimp logo" width="34px" />
      </div>
    ),
    title: "Mailchimp",
    description: "Capture emails in your Mailchimp audiences.",
    url: "https://www.trylantern.com/docs/l/mailchimp",
    category: Category.MARKETING,
    type: IntegrationType.Mailchimp,
    Modal: MailchimpModal,
    requiresConnection: true,
  },
  {
    icon: (
      <div style={{ width: "34px" }}>
        <img src="/logos/zapier.png" alt="Mailchimp logo" width="34px" />
      </div>
    ),
    title: "Zapier",
    description: "Send quiz data to 5,000+ apps with Zapier automations.",
    url: "https://www.trylantern.com/docs/l/zapier",
    category: Category.AUTOMATIONS,
    type: IntegrationType.Zapier,
    requiresConnection: false,
  },
].filter(({ type }) => type && Object.values(IntegrationType).includes(type));

const Integrations = () => {
  const sdk = useSdk();

  const [category, setCategory] = useState<Category>(Category.ALL);
  const [modal, setModal] = useState<boolean | IntegrationType>(false);

  const { data } = useQuery(
    ["integrations"],
    () => sdk.integrations().then((res) => res.integrations?.edges),
    { suspense: true }
  );

  const activeIntegrations = data?.map(({ node }) => node);

  const integrations = useMemo(() => {
    let categoryIntegrations = INTEGRATIONS;

    if (category !== Category.ALL) {
      categoryIntegrations = INTEGRATIONS.filter(
        ({ category: c }) => c === category
      );
    }

    return categoryIntegrations;
  }, [category]);

  return (
    <>
      {!integrations.length ? (
        <Stack vertical>
          <TextContainer>
            <Heading>No results found</Heading>Try clearing or changing your
            search query
          </TextContainer>
          <Button
            plain
            onClick={() => {
              setCategory(Category.ALL);
            }}
          >
            Clear filters
          </Button>
        </Stack>
      ) : (
        integrations.map(
          ({
            description,
            url,
            type,
            Modal, // connectUrl,
            ...integration
          }) => (
            <Fragment key={integration.title}>
              <IntegrationCard
                {...integration}
                type={type}
                description={
                  <>
                    {description}{" "}
                    {!!url && (
                      <Link url={url} external>
                        Read more
                      </Link>
                    )}
                  </>
                }
                isConnected={
                  activeIntegrations?.find((i) => i.type === type)?.status ===
                    IntegrationStatus.Connected ||
                  !integration.requiresConnection
                }
                onClick={() => {
                  setModal(type as IntegrationType);
                }}
              />

              {!!Modal && (
                <Modal
                  integration={activeIntegrations?.find((i) => i.type === type)}
                  type={type as IntegrationType}
                  isOpen={modal === type}
                  onClose={() => {
                    setModal(false);
                  }}
                  name={integration.title}
                />
              )}
            </Fragment>
          )
        )
      )}
    </>
  );
};

export default Integrations;
