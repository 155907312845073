import { FC } from "react";
import { Badge } from "@shopify/polaris";

const CopiedBadge: FC<{ show: boolean; children: any }> = ({
  children,
  show,
}) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
      <span
        style={{
          opacity: `${show ? 100 : 0}`,
          transition: "opacity 0.5s ",
        }}
      >
        <Badge status="success">Copied!</Badge>
      </span>
    </div>
  );
};

export default CopiedBadge;
