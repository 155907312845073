import { ReactNode, useState } from "react";
import { Badge, Button, Card, Heading, Stack } from "@shopify/polaris";
import { IntegrationType } from "~/graphql/sdk";
import { Klaviyo } from "../Connect/Klaviyo";
import { Mailchimp } from "../Connect/Mailchimp";
import { useQuery } from "react-query";
import { useSdk } from "~/hooks";
import { useParams } from "react-router-dom";
import { useBillingWrapper } from "~/lib";
import { FlowStatus } from "~/graphql/sdk";
interface IntegrationCardProps {
  icon?: ReactNode;
  title: string;
  description: string | ReactNode;
  isLoading?: boolean;
  isConnected?: boolean;
  onClick(): void;
  type: IntegrationType;
  requiresConnection?: boolean;
}

export const IntegrationCard = ({
  icon,
  title,
  description,
  isLoading,
  isConnected,
  requiresConnection,
  onClick,
  type,
}: IntegrationCardProps) => {
  const sdk = useSdk();
  const { id } = useParams<{ id: string }>();
  const { data: flowData } = useQuery(
    ["flowContainer", { id }],
    () =>
      sdk
        .container({
          id,
          flowFilter: {
            status: {
              in: [FlowStatus.Active],
            },
          },
        })
        .then((res) => res.flowContainer),
    {
      suspense: true,
    }
  );

  const billingWrapper = useBillingWrapper({ freeForExistingV1Users: true });

  const [klaviyoActivated, setKlaviyoActivated] = useState(
    flowData?.integrationMetadata?.klaviyo?.listId
  );
  const [mailChimpActivated, setMailChimpActivated] = useState(
    flowData?.integrationMetadata?.mailchimp?.listId
  );

  const [showActiveForm, setShowActiveForm] = useState(false);

  return (
    <>
      {type === IntegrationType.Klaviyo &&
      (klaviyoActivated || showActiveForm) ? (
        <Klaviyo
          deactivate={() => {
            setKlaviyoActivated("");
            setShowActiveForm(false);
          }}
          data={flowData}
          id={id}
        />
      ) : null}
      {type === IntegrationType.Mailchimp &&
      (mailChimpActivated || showActiveForm) ? (
        <Mailchimp
          deactivate={() => {
            setMailChimpActivated("");
            setShowActiveForm(false);
          }}
          data={flowData}
          id={id}
        />
      ) : null}
      <br></br>
      {(type === IntegrationType.Klaviyo &&
        !(klaviyoActivated || showActiveForm)) ||
      (type === IntegrationType.Mailchimp &&
        !(mailChimpActivated || showActiveForm)) ? (
        <Card sectioned>
          <Stack alignment="center" wrap={false}>
            {icon}
            <Stack.Item fill>
              <Stack vertical spacing="tight">
                <Stack alignment="center" spacing="tight">
                  <Heading>{title}</Heading>
                  {requiresConnection && isConnected && (
                    <Badge status="success">Connected</Badge>
                  )}
                </Stack>
                <p>{description}</p>
              </Stack>
            </Stack.Item>
            {isConnected || !requiresConnection ? (
              <Button
                loading={isLoading}
                primary={true}
                onClick={() => setShowActiveForm(true)}
              >
                {"Activate"}
              </Button>
            ) : null}
            {requiresConnection && (
              <Button
                loading={isLoading}
                primary={!isConnected}
                plain={isConnected}
                onClick={() => billingWrapper(onClick)()}
              >
                {isConnected ? "Edit" : "Connect"}
              </Button>
            )}
          </Stack>
        </Card>
      ) : null}
    </>
  );
};
