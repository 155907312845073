import { LogicPage } from "~/components/pages";
import { FlowFragmentFragment } from "~/graphql/sdk";

import { PreviewPane } from "../PreviewPane";
import NewPagePanel from "../NewPagePanel";

interface TabPaneProps {
  flow: FlowFragmentFragment;
  isRtl?: boolean;
  tab: number;
}

export const MainPane = ({ tab, ...props }: TabPaneProps) => {
  switch (tab) {
    case 1:
      return <LogicPage />;
    case 7:
      return <NewPagePanel />;
    default:
      return <PreviewPane {...props} />;
  }
};
