import { AnimatePresence } from "framer-motion";

import { EditorPane } from "~/components/sidePanes/EditorPane";
import { useNavParams } from "~/hooks";

import { SideMenu } from "../SideMenu";
import { useEffect, useState } from "react";
// import {DesignPane} from '../DesignPane'

export const LeftSideMenu = () => {
  const [{ tab }] = useNavParams();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const desktopStyles = {
    //3.5rem - TopBar height
    maxHeight: "calc(100vh - 3.5rem)",
    position: "fixed",
    left: "0",
    bottom: "0",
  };

  const mobileStyles = {
    width: "100%",
    height: "50vh",
    overflowY: "auto",
  };

  return (
    <SideMenu
      borderRight="1px solid #EBEAEA"
      topStyles={isMobile ? mobileStyles : desktopStyles}
    >
      {/* @ts-ignore */}
      <AnimatePresence initial={false}>
        <EditorPane key={tab} />
        {/* {props.tab === '1' && <DesignPane />} */}
      </AnimatePresence>
    </SideMenu>
  );
};
