import Integrations from "../Integrations";
import {
  Layout,
  Link,
  Page,
  Stack,
  Card,
  TextField,
  Text,
} from "@shopify/polaris";
import { useEffect } from "react";
import { useFlow } from "~/hooks";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "zapier-app-templates": any;
    }
  }
}

export const NotificationsPage = () => {
  const { data } = useFlow();

  useEffect(() => {
    // ZAP TEMPLATES
    // Load JS
    const script = document.createElement("script");
    script.type = "module";
    script.src =
      "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
    document.head.appendChild(script);

    // Load CSS
    const stylesheet = document.createElement("link");
    stylesheet.rel = "stylesheet";
    stylesheet.href =
      "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
    document.head.appendChild(stylesheet);

    // Create and display zapier-workflow
    const element = document.createElement("zapier-workflow") as any;
    element.signUpEmail = "email_of_your_user@example.com";
    element.signUpFirstName = "first_name_of_your_user";
    element.signUpLastName = "last_name_of_your_user";
    element.clientId = "MxsPuW9ulNCm9qIkIzUb08ZefmqLEqyLvd42fiqd";
    element.theme = "light";
    element.introCopyDisplay = "show";
    const container =
      document.querySelector("#zapier-container") || document.body;
    container.appendChild(element);
  }, []);

  const flowSlug = data?.slug || "";




  return (
    <Page title="Integrations">
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Stack alignment="center" distribution="center" wrap={false}>
              <Stack.Item fill>
                <TextField
                  autoComplete="off"
                  label="Quiz ID"
                  value={flowSlug}
                  readOnly
                />
              </Stack.Item>
            </Stack>
          </Card>
        </Layout.Section>
        <Layout.Section>
          <Text as="p" variant="headingMd">
            Email Integrations
          </Text>
          <Integrations />        
        </Layout.Section>        
        <Layout.Section>
          <Stack vertical>
            <Text as="p" variant="headingMd">
              Zap Templates
            </Text>
            <Card sectioned>
              <Text as="p" variant="bodyMd">
                Connect Lantern to 5,000+ other apps using{" "}
                <Link url={`https://zapier.com`} external>
                  Zapier
                </Link>
                . Search for Lantern from your{" "}
                <Link url={`https://zapier.com`} external>
                  Zapier account
                </Link>{" "}
                or use one of the example templates below.
              </Text>
            </Card>
            <div id="zapier-container"></div>
          </Stack>
        </Layout.Section>
      </Layout>
    </Page>
  );
};
