import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  EmptySearchResult,
  Icon,
  IconSource,
  Spinner,
  Stack,
  TextField,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { Box, Flex, Text } from "@storyofams/react-ui";
import axios from "axios";
import { debounce } from "lodash";
import { useInfiniteQuery } from "react-query";
import { useIntersection } from "react-use";

import config from "~/config";
import { UrlFile } from "~/graphql/sdk";
import { ErrorBanner } from "../ErrorBanner";

import { Credit } from "./Credit";
import { MediaModalPaneProps } from "./MediaModal";
import { VideoImg } from "./VideoImg";

const PER_PAGE = 12;

export interface VideoPaneProps {
  onSelectVideo(video: UrlFile): void;
}

export const VideoPane = ({
  onSelectVideo,
}: VideoPaneProps & MediaModalPaneProps) => {
  const [query, setQuery] = useState("");
  const [params, setParams] = useState({
    query: "",
  });
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const setParamsDebounced = useCallback(
    debounce(setParams, 300, { trailing: true }),
    [setParams]
  );

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    error,
  } = useInfiniteQuery<any>(
    ["searchPexels", params],
    ({ pageParam }) =>
      axios
        .get(`${config.apiUrl}/content/proxy/pexels`, {
          params: {
            query: params.query || undefined,
            page: pageParam,
          },
        })
        .then((res) => {
          const { videos, total_results } = res.data;

          return { results: videos, total: total_results };
        }),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.total / PER_PAGE > allPages.length
          ? allPages.length + 1
          : undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (intersection?.isIntersecting && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [intersection?.isIntersecting]);
  return (
    <Stack vertical>
      <TextField
        autoComplete="off"
        focused
        clearButton
        onChange={(value) => {
          setQuery(value);
          setParamsDebounced({
            query: value,
          });
        }}
        onClearButtonClick={() => {
          setQuery("");
          setParams({
            query: "",
          });
        }}
        value={query}
        label="Search videos"
        labelHidden
        placeholder="Search free videos on Pexels"
        prefix={<Icon source={SearchIcon as IconSource} />}
      />

      <Flex justifyContent="center" alignItems="center" minHeight="200px">
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : error ? (
          <Box px={2}>
            <ErrorBanner error={error} />
          </Box>
        ) : data?.pages?.[0]?.results?.length ? (
          <Box width="100%">
            <Flex flexWrap="wrap" flexDirection="row" mx="-8px">
              {data.pages.map((page, i) => (
                <Fragment key={i}>
                  {page.results?.map(({ id, image, user, video_files }) => (
                    <Box
                      width="calc(33.33333% - 8px)"
                      height="140px"
                      key={id}
                      mx="4px"
                      mb="1"
                      position="relative"
                      borderRadius="8px"
                      overflow="hidden"
                      cursor="pointer"
                      onClick={() => {
                        const video = video_files?.[0];

                        onSelectVideo({
                          url: video?.link,
                          width: video?.width,
                          height: video?.height,
                        });
                      }}
                      css={{
                        "&:hover .credit": {
                          opacity: 1,
                        },
                      }}
                    >
                      <VideoImg src={image} />

                      <Credit className="credit">
                        <Text
                          as={"a" as any}
                          href={`${user.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          pointerEvents="initial"
                          color="white"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {user.name}
                        </Text>
                      </Credit>
                    </Box>
                  ))}
                </Fragment>
              ))}
            </Flex>
          </Box>
        ) : (
          <EmptySearchResult
            title="No videos found"
            description="Try changing the search term"
            withIllustration
          />
        )}
      </Flex>

      <div ref={intersectionRef} />

      {data?.pages?.[0]?.results?.length && hasNextPage && !isLoading && (
        <Flex width="100%" alignItems="center" justifyContent="center" p="4">
          {(hasNextPage || isFetchingNextPage) && <Spinner />}
        </Flex>
      )}
    </Stack>
  );
};
