import { Icon, SystemProps, Text } from "@storyofams/react-ui";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { useIsRtl } from "~/hooks";

import { ReactComponent as arrowLeft } from "../Icon/library/arrow-left.svg";
import { Link } from "../Link";

import { messages } from "./messages";

const StyledLink = styled(Link)<{ isRtl?: boolean }>`
  svg {
    transition: transform 0.18s ease-in-out;

    ${(p) =>
      p.isRtl &&
      css`
        transform: rotate(180deg);
      `}
  }

  &:hover svg {
    transform: ${(p) =>
      p.isRtl ? "rotate(180deg) translateX(-3px)" : "translateX(-3px)"};
  }
`;

interface BackButtonProps extends SystemProps {
  onClick?(): void;
  to?: string;
}

export const BackButton = (props: BackButtonProps) => {
  const isRtl = useIsRtl();

  return (
    <StyledLink className="back-button" variant="dark" isRtl={isRtl} {...props}>
      <Icon
        className="back-button-icon"
        icon={arrowLeft as any}
        css={{ marginInlineEnd: "8px" }}
      />
      <Text className="back-button-text">
        <FormattedMessage {...messages.back} />
      </Text>
    </StyledLink>
  );
};
