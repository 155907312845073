import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  ChoiceList,
  Modal,
  Filters,
  Pagination,
  ResourceList,
  ResourceItem,
  TextStyle,
  EmptySearchResult,
  Select,
  Spinner,
  Stack,
  Thumbnail,
  TextField,
  IconSource,
} from "@shopify/polaris";
import { DuplicateIcon, ImageIcon } from "@shopify/polaris-icons";
import { Box, Flex } from "@storyofams/react-ui";
import { debounce } from "lodash";
import { useQuery } from "react-query";

import { CollectionSortKeys, ProductSortKeys } from "~/graphql/sdk";
import { useSdk } from "~/hooks";
import { getShopifyImage, useToast } from "~/lib";

import { ErrorBanner } from "~/components/ErrorBanner";

interface ProductsModalProps {
  active: boolean;
  edit?: boolean;
  toggleActive(): void;
  selectedOptions: Array<string>;
  selectedVariantOptions: Array<string>;
  setSelectedOptions: (value: Array<string>) => void;
  setSelectedVariantOptions: (value: Array<string>) => void;
}

interface Page {
  after?: string;
  before?: string;
}

interface Params {
  collection: null | string;
  productType: null | string;
  taggedWith: string;
  queryValue: string;
  sortValue: string;
  page: Page;
}

const PAGE_SIZE = 25;

const sortOptions = [
  { label: "Title A-Z", value: "TITLE_ASC" },
  { label: "Title Z-A", value: "TITLE_DESC" },
  { label: "Newest", value: "DATE_CREATED_DESC" },
  { label: "Oldest", value: "DATE_CREATED_ASC" },
];

const getSorting = (value: string) => {
  switch (value) {
    case "TITLE_ASC":
      return { sortKey: ProductSortKeys.Title, reverse: false };
    case "TITLE_DESC":
      return { sortKey: ProductSortKeys.Title, reverse: true };
    case "DATE_CREATED_DESC":
      return { sortKey: ProductSortKeys.CreatedAt, reverse: true };
    case "DATE_CREATED_ASC":
      return { sortKey: ProductSortKeys.CreatedAt, reverse: false };
    default:
      return {};
  }
};

const isEmpty = (value) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null;
  }
};

const uniqueArray = (arr) => {
  return arr.filter((v, i, a) => a.indexOf(v) === i);
};

export const ProductsModal = ({
  active,
  edit,
  selectedOptions,
  setSelectedOptions,
  selectedVariantOptions,
  setSelectedVariantOptions,
  toggleActive,
}: ProductsModalProps) => {
  const toast = useToast();
  const sdk = useSdk();

  const [isBusy, setBusy] = useState(false);
  const [showAllVariants, setShowAllVariants] = useState(false);
  const [variantsForProduct, setVariantsForProduct] = useState<any>(null);
  const [allVariants, setAllVariants] = useState<any>([]);

  const [selectedItems, setSelectedItems] = useState<any>([
    ...selectedOptions,
    ...selectedVariantOptions,
  ]);

  const [searchCollection, setSearchCollection] = useState<any>("");

  useEffect(() => {
    setSelectedItems([...selectedOptions, ...selectedVariantOptions]);
  }, [selectedOptions, selectedVariantOptions, setSelectedItems]);

  const showVariants = async (id) => {
    const result = await sdk
      .shopifyVariantsByProductId({
        input: {
          id,
        },
      })
      .then((res) => res.shopifyVariantsByProductId);

    if (result?.variants?.edges?.length) {
      setAllVariants(result?.variants?.edges);
      setVariantsForProduct(id);
      setShowAllVariants(true);
    }
  };

  const handleSelectionChange = (input) => {
    setSelectedItems((prevSelectedItems) => {
      if (
        prevSelectedItems.filter((p) => !p.includes("###variant###")).length ===
        input.length
      ) {
        // if all products are selected, deselect all
        return prevSelectedItems.filter((p) => p.includes("###variant###"));
      } else {
        // keep all the variants, add the new products
        return uniqueArray([
          ...prevSelectedItems.filter((p) => p.includes("###variant###")),
          ...input,
        ]);
      }
    });
  };

  const handleVariantSelectionChange = (input) => {
    setSelectedItems((prevSelectedItems) => {
      if (
        prevSelectedItems.filter((p) =>
          p.includes(`${variantsForProduct}###variant###`)
        ).length === input.length
      ) {
        // if all the variants of current product are selected, deselect all
        return prevSelectedItems.filter(
          (p) => !p.includes(`${variantsForProduct}###variant###`)
        );
      } else {
        // keep current selection of products and variants of other products, add the new variants
        return uniqueArray([
          ...prevSelectedItems.filter(
            (p) => !p.includes(`${variantsForProduct}###variant###`)
          ),
          ...input,
        ]);
      }
    });
  };

  useEffect(() => {
    if (active) {
      setSelectedItems([...selectedOptions, ...selectedVariantOptions]);
    }
  }, [active]);

  const [params, setParamsState] = useState<Params>({
    collection: null,
    productType: null,
    taggedWith: "",
    queryValue: "",
    sortValue: "TITLE_ASC",
    page: {},
  });

  const [debouncedParams, setDebouncedParams] = useState<Params>(params);

  const setParamsDebounced = useCallback(
    debounce(setDebouncedParams, 300, { trailing: true }),
    [setParamsState]
  );

  useEffect(() => {
    setParamsDebounced(params);
  }, [params]);

  const setParams = (p: Partial<Params>) => {
    setParamsState({ ...params, ...p });
  };

  const setCollection = (value: string | null) => {
    setParams({
      page: {},
      collection: value,
    });
  };

  const { isFetching, isLoading, data, error } = useQuery(
    ["productsSearch", debouncedParams],
    () => {
      const {
        collection,
        page,
        productType,
        taggedWith,
        queryValue,
        sortValue,
      } = debouncedParams;

      let pagination: any = { ...page };

      if (page?.before) {
        pagination.last = PAGE_SIZE;
      } else {
        pagination.first = PAGE_SIZE;
      }

      const input = {
        ...pagination,
        ...getSorting(sortValue),
        query: `state:ACTIVE${queryValue ? ` AND ${queryValue}` : ""}${
          productType ? ` AND product_type:"${productType}"` : ""
        }${taggedWith ? ` AND tag:"${taggedWith}"` : ""}`,
      };

      if (collection) {
        return sdk
          .shopifyProductsByCollection({
            input: {
              handle: collection,
              ...input,
              query: undefined,
            },
          })
          .then((res) => res?.shopifyProductsByCollection);
      }

      return sdk
        .shopifyProducts({
          input,
        })
        .then((res) => res.shopifyProducts);
    },
    { enabled: active, refetchOnWindowFocus: false }
  );

  const products = data?.edges?.map(({ node }) => node) || [];

  const hasMoreItems =
    !!data?.pageInfo?.hasNextPage || !!data?.pageInfo?.hasPreviousPage;

  const { data: collectionsData } = useQuery(
    ["collections", searchCollection],
    () =>
      sdk
        .shopifyCollections({
          input: {
            first: 250,
            sortKey: CollectionSortKeys.Title,
            query: searchCollection,
          },
        })
        .then((res) => res.shopifyCollections),
    { enabled: active, refetchOnWindowFocus: false }
  );
  const collections = useMemo(
    () =>
      collectionsData?.edges?.map(({ node }) => ({
        label: node.title,
        value: node.handle,
      })) || [],
    [collectionsData]
  );

  const { data: productTypesData } = useQuery(
    ["productTypes"],
    () =>
      sdk
        .shopifyProductTypes({
          input: {
            first: 250,
          },
        })
        .then((res) => res.shopifyProductTypes),
    { enabled: active, refetchOnWindowFocus: false }
  );

  const productTypes = useMemo(
    () =>
      productTypesData?.edges?.map(({ node }) => ({
        label: node,
        value: node,
      })) || [],
    [productTypesData]
  );

  const disambiguateLabel = useCallback(
    (key: string, value: any) => {
      switch (key) {
        case "collection":
          return `Collection is ${
            collectionsData?.edges?.find(({ node }) => node.handle === value)
              ?.node?.title || value
          }`;
        case "productType":
          return `Product type is ${value}`;
        case "taggedWith":
          return `Tagged with ${value}`;
        default:
          return value;
      }
    },
    [collectionsData]
  );

  const onSave = async () => {
    if (!selectedItems?.length) {
      toast({ content: "Select at least 1 product to add." });
      return;
    }

    if (isBusy) {
      return;
    }

    setBusy(true);

    try {
      let productIds = selectedItems.filter(
        (id: string) => !id.includes("###variant###")
      );

      if (selectedItems === "All") {
        let hasNextPage = true;
        let cursor;
        productIds = [];

        while (hasNextPage) {
          const result = await sdk
            .shopifyProducts({
              input: {
                first: PAGE_SIZE,
                ...(cursor ? { after: cursor } : {}),
              },
            })
            .then((res) => res.shopifyProducts);

          hasNextPage = !!result.pageInfo.hasNextPage;
          cursor = result.edges?.[result.edges.length - 1]?.cursor;
          productIds.push(...result.edges?.map(({ node }) => node.id));
        }
      }

      setSelectedOptions(productIds);

      const variantIds = selectedItems.filter((id: string) =>
        id.includes("###variant###")
      );

      setSelectedVariantOptions(variantIds);

      toast({
        content: edit
          ? "Product selection saved."
          : "Product(s) added to flow.",
      });
      toggleActive();
      setShowAllVariants(false);
    } catch (e: any) {
      toast({
        error: true,
        content:
          e?.messages?.[0] ||
          e?.message ||
          `Error ${edit ? "saving selection" : "adding products"}.`,
      });
    }

    setBusy(false);
  };

  const emptyStateMarkup = useMemo(() => {
    let content;

    if (isLoading || (isFetching && !products?.length)) {
      content = <Spinner accessibilityLabel="Loading products" size="large" />;
    } else if (error) {
      console.log({ error });
      content = (
        <Box px={2}>
          <ErrorBanner error={error} />
        </Box>
      );
    } else {
      content = (
        <EmptySearchResult
          title="No products found"
          description="Try changing the filters or search term"
          withIllustration
        />
      );
    }

    return (
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="200px"
      >
        {content}
      </Flex>
    );
  }, [isLoading, isFetching, error, products]);

  const handleClearAll = useCallback(() => {
    setParams({
      collection: null,
      productType: null,
      queryValue: "",
      taggedWith: "",
      page: {},
    });
  }, [setParams]);

  const filters = [
    {
      key: "collection",
      label: "Collection",
      filter: (
        <>
          <div style={{ marginBottom: "5px" }}>
            <TextField
              autoComplete="off"
              label=""
              value={searchCollection}
              onChange={(v) => {
                setSearchCollection(v);
              }}
              labelHidden
            />
          </div>
          <ChoiceList
            title="Collection"
            titleHidden
            choices={collections}
            selected={params.collection ? [params.collection] : []}
            onChange={(selected) => {
              setCollection(selected?.[0]);
            }}
          />
        </>
      ),
      shortcut: true,
      disabled:
        !!params.productType || !!params.taggedWith || !!params.queryValue,
    },
    {
      key: "productType",
      label: "Product type",
      filter: (
        <ChoiceList
          title="Product type"
          titleHidden
          choices={productTypes}
          selected={params.productType ? [params.productType] : []}
          onChange={(selected) => {
            setParams({ productType: selected?.[0] });
          }}
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          autoComplete="off"
          label="Tagged with"
          value={params.taggedWith}
          onChange={(taggedWith) => {
            setParams({ taggedWith });
          }}
          labelHidden
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = useMemo(() => {
    const applied: any[] = [];

    if (!isEmpty(params.taggedWith)) {
      applied.push({
        key: "taggedWith",
        label: disambiguateLabel("taggedWith", params.taggedWith),
        onRemove: () => {
          setParams({ taggedWith: "" });
        },
      });
    }

    if (!isEmpty(params.collection)) {
      applied.push({
        key: "collection",
        label: disambiguateLabel("collection", params.collection),
        onRemove: () => {
          setCollection(null);
        },
      });
    }

    if (!isEmpty(params.productType)) {
      applied.push({
        key: "productType",
        label: disambiguateLabel("productType", params.productType),
        onRemove: () => {
          setParams({ productType: null });
        },
      });
    }

    return applied;
  }, [params.taggedWith, params.collection, params.productType]);

  const toggleSelected = useCallback(
    (id: string) => {
      const idx = selectedItems?.indexOf(id);

      if (idx !== -1) {
        setSelectedItems([...selectedItems?.filter((item) => item !== id)]);
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    },
    [selectedItems, setSelectedItems]
  );

  const toggleSelectedVariant = useCallback(
    (vid: string) => {
      if (!variantsForProduct) return;
      const id = `${variantsForProduct}###variant###${vid}`;
      const idx = selectedItems?.indexOf(id);

      if (idx !== -1) {
        setSelectedItems([...selectedItems?.filter((item) => item !== id)]);
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    },
    [selectedItems, setSelectedItems, variantsForProduct]
  );

  const renderVariant = (item) => {
    if (!item?.node?.id) return null;

    const { id, title } = item.node;

    return (
      <ResourceItem
        id={`${variantsForProduct}###variant###${id}`}
        onClick={() => {
          toggleSelectedVariant(id);
        }}
        verticalAlignment="center"
        accessibilityLabel={`Select ${title}`}
      >
        <TextStyle>{title}</TextStyle>
      </ResourceItem>
    );
  };

  const renderItem = (item) => {
    const { id, featuredImage, title } = item;

    const media = (
      <Thumbnail
        size="small"
        alt=""
        source={
          featuredImage?.transformedSrc
            ? getShopifyImage(featuredImage?.transformedSrc, "80x80")
            : ImageIcon
        }
      />
    );

    const totalSelectedVariants = selectedItems.filter((p) =>
      p.includes(`${id}###variant###`)
    ).length;

    return (
      <ResourceItem
        id={id}
        onClick={() => {
          toggleSelected(id);
        }}
        verticalAlignment="center"
        media={media}
        accessibilityLabel={`Select ${title}`}
      >
        <TextStyle>{title}</TextStyle>{" "}
        {item?.variants?.edges?.length > 1 && (
          <div
            style={{
              display: "inline-flex",
              margin: "10px 20px",
              color: "#084355",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              showVariants(id);
            }}
          >
            <Button
              outline
              monochrome
              size="slim"
              icon={DuplicateIcon as IconSource}
            >
              Select Variants
            </Button>
            {totalSelectedVariants > 0 && (
              <span style={{ marginLeft: "10px" }}>
                <TextStyle variation="strong">
                  ({totalSelectedVariants} variant
                  {totalSelectedVariants === 1 ? "" : "s"} selected)
                </TextStyle>
              </span>
            )}
          </div>
        )}
      </ResourceItem>
    );
  };

  if (showAllVariants && variantsForProduct) {
    return (
      <Modal
        large
        open={showAllVariants}
        onClose={() => {
          setShowAllVariants(false);
        }}
        title={`Select variants of ${
          products.find((p) => p.id === variantsForProduct)?.title
        }`}
        primaryAction={{
          content: edit ? "Save selection" : "Add variants",
          onAction: onSave,
          loading: isBusy,
        }}
        secondaryActions={[
          {
            content: "Back to products",
            onAction: () => setShowAllVariants(false),
          },
        ]}
      >
        <Modal.Section flush>
          <Box
            maxHeight="700px"
            css={{
              ".Polaris-ResourceList__ItemWrapper": {
                minHeight: "200px",
              },
            }}
          >
            <Stack vertical>
              <ResourceList
                resourceName={{
                  singular: "variant",
                  plural: "variants",
                }}
                items={error ? [] : allVariants}
                hasMoreItems={false}
                renderItem={renderVariant}
                emptyState={emptyStateMarkup}
                selectedItems={selectedItems}
                onSelectionChange={handleVariantSelectionChange}
                idForItem={(item) =>
                  `${variantsForProduct}###variant###${item.node.id}`
                }
                selectable
              />
            </Stack>
          </Box>
        </Modal.Section>
      </Modal>
    );
  } else {
    return (
      <Modal
        large
        open={active}
        onClose={toggleActive}
        title={"Change products"}
        primaryAction={{
          content: edit ? "Save selection" : "Add products",
          onAction: onSave,
          loading: isBusy,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleActive,
          },
        ]}
      >
        <Modal.Section flush>
          <Box
            maxHeight="700px"
            css={{
              ".Polaris-ResourceList__ItemWrapper": {
                minHeight: "200px",
              },
            }}
          >
            <Stack vertical>
              <ResourceList
                resourceName={{
                  singular: "product",
                  plural: "products",
                }}
                items={error ? [] : products}
                hasMoreItems={false}
                renderItem={renderItem}
                emptyState={emptyStateMarkup}
                selectedItems={selectedItems}
                onSelectionChange={handleSelectionChange}
                selectable
                sortValue={params.sortValue}
                onSortChange={(sortValue) => {
                  setParams({ sortValue });
                }}
                filterControl={
                  <Flex>
                    <Box flex="1" mr={1}>
                      <Filters
                        queryPlaceholder="Search products..."
                        queryValue={params.queryValue}
                        filters={filters}
                        appliedFilters={appliedFilters}
                        onQueryChange={(queryValue) => {
                          setParams({ queryValue });
                        }}
                        onQueryClear={() => {
                          setParams({ queryValue: "" });
                        }}
                        onClearAll={handleClearAll}
                        disabled={!!params.collection}
                        helpText={
                          params.collection ? (
                            <Stack alignment="center" spacing="tight">
                              <Button onClick={handleClearAll} size="slim">
                                Clear all filters
                              </Button>
                              <TextStyle variation="subdued">
                                Clear the collection filter to search or add
                                more product filters.
                              </TextStyle>
                            </Stack>
                          ) : undefined
                        }
                      />
                    </Box>

                    <Select
                      label="Sort by"
                      labelInline
                      options={sortOptions}
                      onChange={(sortValue) => {
                        setParams({ sortValue });
                      }}
                      value={params.sortValue}
                    />
                  </Flex>
                }
              />

              {hasMoreItems && (
                <Flex justifyContent="center" px={2} pb={2}>
                  <Pagination
                    hasPrevious={!!data?.pageInfo?.hasPreviousPage}
                    onPrevious={() => {
                      setParams({ page: { before: data?.edges?.[0]?.cursor } });
                    }}
                    hasNext={!!data?.pageInfo?.hasNextPage}
                    onNext={() => {
                      setParams({
                        page: {
                          after: data?.edges?.[data?.edges?.length - 1]?.cursor,
                        },
                      });
                    }}
                  />
                </Flex>
              )}
            </Stack>
          </Box>
        </Modal.Section>
      </Modal>
    );
  }
};
