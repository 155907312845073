import { useEffect } from "react";
import { useWatch } from "react-hook-form";
import { isEqual } from "lodash";

import { useFlow } from "~/hooks";

interface FormSyncProps {
  control: any;
  flowNodeId: string;
}

export const useFormSync = ({ control, flowNodeId }: FormSyncProps) => {
  const { updateFlowNode, lastUpdate } = useFlow();
  const values = useWatch({
    control,
  });

  useEffect(() => {
    if (flowNodeId && values) {
      if (!isEqual(values, lastUpdate)) {
        updateFlowNode(flowNodeId, values);
      }
    }
  }, [values]);
};
