import { Controller } from "react-hook-form";

import { Editor } from "../Editor";

export const toEditorValue = (text: string) => [
  {
    type: "paragraph",
    children: [{ text: text || "" }],
  },
];

export const getEditorValue = (input: any[]) => input?.[0]?.children?.[0]?.text;

interface EditorControllerProps {
  control: any;
  defaultValue?: string;
  dir?: "rtl" | "ltr" | "auto";
  editorRef?: any;
  element: any;
  name: string;
  placeholder: string;
  className?: string;
  style?: any;
}

export const EditorController = ({
  control,
  dir,
  element,
  name,
  defaultValue,
  editorRef,
  placeholder,
  className,
  style,
}: EditorControllerProps) => (
  <Controller
    defaultValue={defaultValue}
    control={control}
    name={name}
    render={({ field }) => (
      <Editor
        element={element}
        placeholder={placeholder}
        {...field}
        dir={dir}
        editorRef={editorRef}
        className={className}
        onChange={(value) => {
          return field.value === getEditorValue(value)
            ? field.value
            : field.onChange(getEditorValue(value));
        }}
        value={toEditorValue(field.value)}
        style={style}
      />
    )}
  />
);
