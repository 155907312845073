import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { isBefore } from "date-fns";
import BillingModal from "~/components/BillingAlertModal";
import { useStore } from "~/hooks";

export enum PLANS_ENUM {
  "BASIC" = "Basic",
  "GROWTH" = "Growth",
}

export enum PLANS_ENUM_V2 {
  "FREE" = "Free",
  "PRO" = "Pro",
  "ADVANCED" = "Advanced",
  "ENTERPRISE" = "Enterprise",
}

interface ContextProps {
  setBillingModalOpen: React.Dispatch<boolean>;
  setIsInitialBillingModal: React.Dispatch<boolean>;
}

type AccessFlags = {
  freeMultiQuestionAccess?: boolean;
  freeForExistingV1Users?: boolean;
  freeBeforeDate?: Date;
};

const BillingAlertContext = createContext<ContextProps | undefined>(undefined);

type BillingProviderProps = {
  children: ReactNode;
};

export const BillingProvider: FC<BillingProviderProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInitialBillingModal, setIsInitialBillingModal] = useState(false);

  return (
    <BillingAlertContext.Provider
      value={{
        setBillingModalOpen: setIsModalOpen,
        setIsInitialBillingModal,
      }}
    >
      {children}
      {
        <BillingModal
          open={isModalOpen}
          setClose={() => setIsModalOpen(false)}
          isInitial={isInitialBillingModal}
          setIsInitial={setIsInitialBillingModal}
        />
      }
    </BillingAlertContext.Provider>
  );
};

const useGetBillingInfo = (freeBeforeDate?: Date) => {
  const context = useContext(BillingAlertContext);
  const store = useStore();
  const storePlan = store?.tier;
  const isMultiQuestionAccess = store?.multiQuestionAccess;

  let isExistingV1User = false;
  if (process.env.REACT_APP_LAUNCH_V2_DATE) {
    const createAtDate = new Date(store?.createdAt);
    const launchDate = new Date(process.env.REACT_APP_LAUNCH_V2_DATE);
    isExistingV1User = isBefore(createAtDate, launchDate);
  }

  let isBeforeFreeDate = false;
  if (freeBeforeDate) {
    const createAtDate = new Date(store?.createdAt);
    const launchDate = freeBeforeDate;
    isBeforeFreeDate = isBefore(createAtDate, launchDate);
  }

  const isCurrentBillingVersion = storePlan?.billingVersion === 1;
  const isV2 = storePlan?.billingVersion === 2;
  const isPaidPlan =
    (isCurrentBillingVersion && storePlan?.name !== PLANS_ENUM.BASIC) ||
    (isV2 && storePlan?.name !== PLANS_ENUM_V2.FREE);

  return {
    context,
    isCurrentBillingVersion,
    isPaidPlan,
    isMultiQuestionAccess,
    isExistingV1User,
    isBeforeFreeDate,
    isV2,
  };
};

export const hasPaidFeatureAccess = () => {
  const { isCurrentBillingVersion, isV2, isPaidPlan } = useGetBillingInfo();

  const hasAccess = (!isCurrentBillingVersion && !isV2) || isPaidPlan;

  return hasAccess;
};

//If the merchant does not have access then the function passed as a child will not execute and a billing modal will pop up instead
export const useBillingWrapper = (accessFlags: AccessFlags = {}) => {
  const {
    context,
    isCurrentBillingVersion,
    isPaidPlan,
    isMultiQuestionAccess,
    isExistingV1User,
    isBeforeFreeDate,
    isV2,
  } = useGetBillingInfo(accessFlags.freeBeforeDate);

  return (fn) => async (args?) => {
    const {
      freeMultiQuestionAccess = false,
      freeForExistingV1Users = false,
      freeBeforeDate,
    } = accessFlags;

    const accessWithMultiFeature =
      freeMultiQuestionAccess && isMultiQuestionAccess;

    const accessForExistingV1Users = freeForExistingV1Users && isExistingV1User;

    const freeAccess = freeBeforeDate && isBeforeFreeDate;

    const hasAccess =
      (!isCurrentBillingVersion && !isV2) ||
      isPaidPlan ||
      accessWithMultiFeature ||
      accessForExistingV1Users ||
      freeAccess;

    if (hasAccess) {
      await fn(args);
      return;
    } else {
      context?.setBillingModalOpen(true);
    }
  };
};

export const useCheckBillingOnMount = (accessFlags: AccessFlags = {}) => {
  const {
    context,
    isCurrentBillingVersion,
    isPaidPlan,
    isMultiQuestionAccess,
    isExistingV1User,
  } = useGetBillingInfo();

  useEffect(() => {
    const { freeMultiQuestionAccess = false, freeForExistingV1Users = false } =
      accessFlags;
    const accessWithMultiFeature =
      freeMultiQuestionAccess && isMultiQuestionAccess;
    const accessForExistingUsers = freeForExistingV1Users && isExistingV1User;

    const hasAccess =
      !isCurrentBillingVersion ||
      isPaidPlan ||
      accessWithMultiFeature ||
      accessForExistingUsers;

    if (!hasAccess) {
      context?.setIsInitialBillingModal(true);

      context?.setBillingModalOpen(true);

      return () => {
        context?.setIsInitialBillingModal(false);
      };
    }
    return;
  }, []);
};
