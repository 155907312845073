import { Section } from "~/components/Section";
import { Controller } from "react-hook-form";
import { useOptionsForm } from "~/hooks";
import { Checkbox, TextField } from "@shopify/polaris";
import { FlowNodeType } from "~/graphql/sdk";
// import { useBillingWrapper } from "~/lib";

export const OPTIONS = [
  { label: "Single choice - text only", value: FlowNodeType.Simple },
  { label: "Single choice - with emoji", value: FlowNodeType.Emoji },
  { label: "Single choice - with image", value: FlowNodeType.Image },
  {
    label: "Multi choice - text only",
    value: FlowNodeType.SimpleMulti,
  },
  {
    label: "Multi choice - with emoji",
    value: FlowNodeType.EmojiMulti,
  },
  {
    label: "Multi choice - with image",
    value: FlowNodeType.ImageMulti,
  },
  {
    label: "Input - one line text",
    value: FlowNodeType.InputOneLineText,
  },
  {
    label: "Input - multi line text",
    value: FlowNodeType.InputMultiLineText,
  },
  {
    label: "Transition Screen",
    value: FlowNodeType.Transition,
  },
  // {
  //   label: "Input - calendar",
  //   value: FlowNodeType.InputCalendar,
  // },
  // {
  //   label: "Input - slider",
  //   value: FlowNodeType.InputSlider,
  // },
];

export const INPUT_FIELD_TYPE_OPTIONS = [
  { label: "text", value: "text" },
  { label: "number", value: "number" },
];

const getDefaultValues = (values) => ({
  type: values?.type || FlowNodeType.InputOneLineText,
  isRequired:
    typeof values?.isRequired !== "undefined" ? !!values.isRequired : true,
  propertyID: values?.propertyID || "",
  klaviyoID: values?.klaviyoID || "",
  mailchimpID: values?.mailchimpID || "",
  inputFieldType: values?.inputFieldType || "text",
  maxLength: values?.maxLength || "",
  minLength: values?.minLength || "",
  inputWidth: values?.inputWidth || "375px",
  inputHeight: values?.inputHeight || "112px",
});

const InputMultiLineTextOptions = () => {
  const form = useOptionsForm({
    getDefaultValues,
    type: "flowNode",
  });
  const control = form.control;

  return (
    <>
      <Section title="Question options">
        <Controller
          control={control}
          name="maxLength"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Max. Characters"
              autoComplete="off"
              placeholder="leave blank for no limit"
              value={field.value}
              name={field.name}
              type="number"
              onChange={field.onChange}
              helpText="Limit the number of characters that can be entered."
            />
          )}
        />

        <Controller
          control={control}
          name="minLength"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Min. Characters"
              autoComplete="off"
              placeholder="leave blank for no minimum"
              value={field.value}
              name={field.name}
              type="number"
              onChange={field.onChange}
              helpText="Require a minimum of characters to be entered."
            />
          )}
        />

        <Controller
          control={control}
          name="inputHeight"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Height"
              autoComplete="off"
              placeholder="e.g. 112px"
              value={field.value}
              name={field.name}
              type="text"
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="inputWidth"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Width"
              autoComplete="off"
              placeholder="e.g. 375px"
              value={field.value}
              name={field.name}
              type="text"
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="propertyID"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Property ID"
              autoComplete="off"
              placeholder="e.g. first_name, age, skinType"
              value={field.value}
              name={field.name}
              error={field.value.length === 0 ? "Property ID is required" : ""}
              onChange={(v) => field.onChange(v.replace(/\s/g, ""))}
              helpText="This should be a unique identifier for the answer."
            />
          )}
        />
      </Section>
      <Section>
        <Controller
          control={control}
          name="isRequired"
          render={({ field: { value, ...field } }) => (
            <Checkbox label="Required" checked={value} {...field} />
          )}
        />
      </Section>
      <Section title="Integration options">
        <Controller
          control={control}
          name="klaviyoID"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Klaviyo ID"
              autoComplete="off"
              placeholder="e.g. first_name"
              value={field.value}
              name={field.name}
              onChange={(v) => field.onChange(v.trim())}
              helpText="Klaviyo ID for this profile property"
            />
          )}
        />
        <Controller
          control={control}
          name="mailchimpID"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Mailchimp ID"
              autoComplete="off"
              placeholder="e.g. FNAME"
              value={field.value}
              name={field.name}
              onChange={(v) => field.onChange(v.trim())}
              helpText="Mailchimp merge tag for this contact property"
            />
          )}
        />
      </Section>
    </>
  );
};

export default InputMultiLineTextOptions;
