import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";

import { AppProvider, Frame } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import * as Yup from "yup";

import "@shopify/polaris/build/esm/styles.css";

import { Link } from "~/components";
import { App } from "~/containers";
// import { OfflineProvider } from "~/context";
import { ShopProvider } from "~/context";
import { BillingProvider, getSdk, getShopOrigin, ToastProvider } from "~/lib";
import { CSSReset } from "~/styles/CSSReset";
import theme from "~/styles/theme";
import { cookieFetch } from "./hooks";

Yup.setLocale({
  mixed: {
    required: "This field is required",
  },
  string: {
    email: "Invalid email",
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const ShopRouter = ({ children }) => {
  const shop = getShopOrigin();

  if (!shop) {
    /** @todo handle missing shop origin */
    return null;
  }

  return (
    <ShopProvider shop={shop}>
      <BrowserRouter
        basename={`${
          process.env.NODE_ENV === "development" ? "/flow-builder" : ""
        }/${shop}`}
      >
        {children}
      </BrowserRouter>
    </ShopProvider>
  );
};

const render = () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      {/* @ts-ignore */}
      <AppProvider linkComponent={Link} i18n={translations}>
        <QueryClientProvider client={queryClient}>
          <Frame>
            <ToastProvider>
              <ShopRouter>
                {/* @ts-ignore */}
                <BillingProvider>
                  {/* <OfflineProvider> */}
                  <Switch>
                    <Route component={App} />
                  </Switch>
                  {/* </OfflineProvider> */}
                </BillingProvider>
              </ShopRouter>
            </ToastProvider>
          </Frame>

          <CSSReset />
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </AppProvider>
    </ThemeProvider>,
    document.getElementById("perfect-product-finder"),
  );
};

const checkSession = () =>
  new Promise<string>(async (resolve, reject) => {
    try {
      const shop = getShopOrigin();

      if (!shop) {
        reject();
        return;
      }

      const sdk = getSdk(cookieFetch(shop));

      try {
        await sdk.currentStore();
      } catch (e) {}

      resolve(shop);
    } catch (e) {
      reject(e);
    }
  });

checkSession()
  .then(render)
  .catch((error) => {
    const root = document.getElementById("page-loader");

    if (root) {
      root.innerHTML = `<div class="full-page"><p>Error opening flow builder, please try again from the Shopify admin.${
        getShopOrigin()
          ? `<br><br><a href="https://${getShopOrigin()}/admin/apps/${
              process.env.REACT_APP_SHOPIFY_APP_NAME
            }/quizzes">Go back</a>`
          : ""
      }</p></div>`;
    }
  });
