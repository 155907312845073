import { Box } from "@storyofams/react-ui";
import styled, { keyframes } from "styled-components";

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

type BoxProps = React.ComponentProps<typeof Box>;

interface ExtendedBoxProps extends BoxProps {
  answersPerRowMobile?: number;
  answersPerRowDesktop?: number;
}

export const OptionWrapper = styled(Box).attrs<ExtendedBoxProps>((attrs) => ({
  width: {
    _: `${
      attrs.answersPerRowMobile === 1
        ? "100%"
        : `calc(${100 / (attrs.answersPerRowMobile ?? 1)}% - 16px)`
    }`,
    lg: `${
      attrs.answersPerRowDesktop === 1
        ? "100%"
        : `calc(${100 / (attrs.answersPerRowDesktop ?? 3)}% - 16px)`
    }`,
  },
  mx: 1,
  mb: [1, 2],
  ...attrs,
}))<ExtendedBoxProps>`
  &.is-dragging {
    .drag-handle {
      opacity: 1;
    }

    .match-warning {
      animation: 0.2s ${fadeOut} ease-out;
      animation-fill-mode: both;
    }
  }
`;
