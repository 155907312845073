import {
  ComponentProps,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import {
  DropZone,
  Icon,
  Stack,
  Button,
  Spinner,
  TextStyle,
  TextField,
  EmptySearchResult,
  IconSource,
} from "@shopify/polaris";
import { SearchIcon, UploadIcon } from "@shopify/polaris-icons";
import { Flex, Box, Text } from "@storyofams/react-ui";
import axios from "axios";
import { debounce, minBy, range } from "lodash";
import qs from "query-string";
import { useInfiniteQuery } from "react-query";
import { useIntersection } from "react-use";

import config from "~/config";
import { UrlFile } from "~/graphql/sdk";
import { ErrorBanner } from "../ErrorBanner";

import { Credit } from "./Credit";
import { Img } from "./Img";
import { MediaModalPaneProps } from "./MediaModal";

const PER_PAGE = 12;

export interface ImagePaneProps {
  onDrop: ComponentProps<typeof DropZone>["onDrop"];
  onSelectImage(image: UrlFile): void;
}

// Remove all imgix params except ixid, this is required for hotlinking
const formatUnsplashUrl = (url: string) => {
  const params = qs.parse(url);

  return `${url.split("?")[0]}?ixid=${params.ixid}`;
};

const trackUnsplashDownload = (downloadLink: string) => {
  axios.post(`${config.apiUrl}/content/proxy/unsplash`, { downloadLink });
};

const Result = ({
  result: { alt_description, urls, links, user, width, height, blur_hash },
  onSelectImage,
}) => (
  <Box
    mb="1"
    position="relative"
    borderRadius="8px"
    overflow="hidden"
    cursor="pointer"
    onClick={() => {
      onSelectImage({
        url: formatUnsplashUrl(urls.full),
        width,
        height,
        blurhash: blur_hash,
      });

      trackUnsplashDownload(links.download_location);
    }}
    css={{
      "&:hover .credit": {
        opacity: 1,
      },
    }}
  >
    <Box aria-hidden pb={`${(height / width) * 100}%`} />

    <Img src={urls.small} alt={alt_description} />

    <Credit className="credit">
      <Text
        as={"a" as any}
        href={`${user.links.html}?utm_source=${config.unsplashAppName}&utm_medium=referral`}
        target="_blank"
        rel="noopener noreferrer"
        pointerEvents="initial"
        color="white"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {user.first_name} {user.last_name}
      </Text>
    </Credit>
  </Box>
);

interface Column {
  items: any[];
  height: number;
}

const Masonry = ({ data, onSelectImage }) => {
  const columns = useMemo(() => {
    const columns: Column[] = range(3).map(() => ({ items: [], height: 0 }));

    data.pages.forEach((page) => {
      page.results?.forEach((item) => {
        const min = minBy(columns, (c) => c.height);

        const idx = min ? columns.indexOf(min) : 0;

        columns[idx].items.push(item);
        columns[idx].height += 185 * (item.height / item.width);
      });
    });

    return columns;
  }, [data]);

  return (
    <>
      {columns.map((column, i) => (
        <Box key={i} width="calc(33.33333% - 8px)" mx="4px">
          {column.items.map((result) => (
            <Result
              key={result.id}
              result={result}
              onSelectImage={onSelectImage}
            />
          ))}
        </Box>
      ))}
    </>
  );
};

export const ImagePane = ({
  onDrop,
  onSelectImage,
  isBusy,
}: ImagePaneProps & MediaModalPaneProps) => {
  const [query, setQuery] = useState("");
  const [params, setParams] = useState({
    query: "",
  });
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  });

  const setParamsDebounced = useCallback(
    debounce(setParams, 300, { trailing: true }),
    [setParams]
  );

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    error,
  } = useInfiniteQuery<any>(
    ["searchUnsplash", params],
    ({ pageParam = 1 }) =>
      axios
        .get(`${config.apiUrl}/content/proxy/unsplash`, {
          params: {
            query: params.query || undefined,
            page: pageParam,
          },
        })
        .then((res) => res.data),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.total / PER_PAGE > allPages.length
          ? allPages.length + 1
          : undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (intersection?.isIntersecting && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [intersection?.isIntersecting]);

  return (
    <Stack vertical>
      <Box width="100%" overflow="hidden" position="relative">
        <DropZone
          accept="image/*"
          type="image"
          onDrop={onDrop}
          allowMultiple={false}
          variableHeight
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            p="3"
            css={{
              ".Polaris-Icon": {
                width: "40px",
                height: "40px",
              },
            }}
          >
            <Stack vertical alignment="center" spacing="tight">
              <Icon source={UploadIcon as IconSource} color="base" />
              <Button>Select image</Button>
              <TextStyle variation="subdued">or drop image to upload</TextStyle>
            </Stack>
          </Flex>
        </DropZone>

        {isBusy && (
          <Flex
            position="absolute"
            alignItems="center"
            justifyContent="center"
            top={0}
            right={0}
            left={0}
            bottom={0}
            bg="white60"
          >
            <Spinner />
          </Flex>
        )}
      </Box>

      <TextField
        autoComplete="off"
        clearButton
        onChange={(value) => {
          setQuery(value);
          setParamsDebounced({
            query: value,
          });
        }}
        onClearButtonClick={() => {
          setQuery("");
          setParams({
            query: "",
          });
        }}
        value={query}
        label="Search photos"
        labelHidden
        placeholder="Search free photos on Unsplash"
        prefix={<Icon source={SearchIcon as IconSource} />}
      />

      <Stack.Item>
        <Flex justifyContent="center" alignItems="center" minHeight="200px">
          {isLoading ? (
            <Flex justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Box px={2}>
              <ErrorBanner error={error} />
            </Box>
          ) : data?.pages?.[0]?.results?.length ? (
            <Box width="100%">
              <Flex flexDirection="row" mx="-8px">
                <Masonry data={data} onSelectImage={onSelectImage} />
              </Flex>
            </Box>
          ) : (
            <EmptySearchResult
              title="No photos found"
              description="Try changing the search term"
              withIllustration
            />
          )}
        </Flex>

        <div ref={intersectionRef} />

        {data?.pages?.[0]?.results?.length && hasNextPage && !isLoading && (
          <Flex width="100%" alignItems="center" justifyContent="center" p="4">
            {(hasNextPage || isFetchingNextPage) && <Spinner />}
          </Flex>
        )}
      </Stack.Item>
    </Stack>
  );
};
