import { useState } from "react";
import {
  Page,
  Layout,
  Card,
  Icon,
  Badge,
  IconSource,
  Banner,
  Button,
  Modal,
  TextStyle,
  Spinner,
  Stack,
  Link,
  Heading,
} from "@shopify/polaris";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  RefreshIcon,
} from "@shopify/polaris-icons";
import { useFlow, useNavParams, useSdk } from "~/hooks";
import { FlowNodeCard } from "../Results/FlowNodeProducts";
import { OPTIONS } from "../sidePanes/OptionsPane/QuestionOptions";
import { FlowNodeType } from "~/graphql/sdk";
import { FlowNodeState } from "~/context";

interface Page {
  after?: string;
  before?: string;
}

export const ResultsPage = () => {
  const { data: flowContainer, flowNode: currentFlowNode } = useFlow();
  const [, setNavParams] = useNavParams();
  const flow = flowContainer?.flows?.[0];

  const [currentNode, setCurrentNode] = useState<
    FlowNodeState["id"] | undefined | null
  >(currentFlowNode?.id);

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showLoadingSync, setShowLoadingSync] = useState(false);
  const [showSyncSuccess, setShowSyncSuccess] = useState(false);

  const sdk = useSdk();

  const hasWelcome = flow?.nodes?.[0]?.type === FlowNodeType.Welcome;

  const sendResyncRequest = async () => {
    // wait for 3 seconds to simulate a long-running request
    try {
      await sdk.syncCollections();
    } catch (e) {
      console.log(e);
    }
  };

  const resyncCollections = () => {
    setShowSyncModal(true);
  };

  return (
    <Page title="Products" subtitle="Select products to match to your answers">
      <Layout>
        <Layout.Section>
          {flow?.nodes.map((node, index) => {
            if (
              node.type !== FlowNodeType.Welcome &&
              node.type !== FlowNodeType.Email
            ) {
              return (
                <Card key={node?.id}>
                  <Card.Section>
                    <div
                      onClick={() => {
                        const isOpen = currentNode === node.id;
                        if (isOpen) {
                          setCurrentNode(null);
                        } else {
                          setCurrentNode(node.id);
                          setNavParams({ question: index.toString() });
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <h1
                          style={{
                            display: "flex",
                            fontSize: 14,
                            marginBottom: 10,
                          }}
                        >
                          {index + (hasWelcome ? 0 : 1) + ". " + node.title}
                          <span>
                            <Icon
                              source={
                                currentNode === node.id
                                  ? (ChevronDownIcon as IconSource)
                                  : (ChevronUpIcon as IconSource)
                              }
                            />
                          </span>
                        </h1>
                      </div>
                      <Badge>
                        {
                          OPTIONS?.find((opt) => opt?.value === node?.type)
                            ?.label
                        }
                      </Badge>
                    </div>
                  </Card.Section>
                  {currentNode === node.id ? <FlowNodeCard /> : null}
                </Card>
              );
            } else {
              return null;
            }
          })}
          <Card sectioned>
            <div style={{ marginBottom: "10px" }}>
              <p style={{ color: "#6D7175" }}>
                Please note that we can only link up to <b>250 products</b> from
                each collection.
              </p>
              <p style={{ color: "#6D7175" }}>
                If you use automated collections, you may need to{" "}
                <Button plain onClick={resyncCollections}>
                  manually re-sync
                </Button>{" "}
                them to see all products.
              </p>
            </div>
          </Card>
          <Card sectioned>
            <div style={{ marginBottom: "10px" }}>
              <Stack
                vertical
                distribution="center"
                alignment="leading"
                spacing="loose"
              >
                <Heading>Help Videos</Heading>
                <p>
                  <Link
                    url="https://www.youtube.com/watch?v=_ofPMzn4NMw"
                    external
                  >
                    How to build your results page
                  </Link>
                </p>
                <p>
                  <Link
                    url="https://www.trylantern.com/docs/l/automated-collection-linking#creating-a-collection-by-tags"
                    external
                  >
                    How to automatically match products based on tags
                  </Link>
                </p>
              </Stack>
            </div>
          </Card>
        </Layout.Section>
      </Layout>
      <Modal
        open={showSyncModal}
        onClose={() => {
          setShowSyncModal(false);
          setShowSyncSuccess(false);
        }}
        title="Resync Collections"
        primaryAction={
          !showSyncSuccess && !showLoadingSync
            ? {
                content: "Resync",
                icon: RefreshIcon as IconSource,
                onAction: async () => {
                  setShowLoadingSync(true);
                  await sendResyncRequest();
                  setShowLoadingSync(false);
                  setShowSyncSuccess(true);
                },
              }
            : undefined
        }
        secondaryActions={[
          {
            content: !showSyncSuccess ? "Cancel" : "Close",
            onAction: () => {
              setShowSyncModal(false);
              setShowSyncSuccess(false);
            },
          },
        ]}
      >
        <Modal.Section>
          {showLoadingSync && (
            <Stack
              vertical
              spacing="loose"
              alignment="center"
              distribution="center"
            >
              <Banner status="info">
                <TextStyle variation="strong">
                  Resyncing collections...
                </TextStyle>
              </Banner>
              <br />
              <Spinner accessibilityLabel="Loading" size="large" />
            </Stack>
          )}
          {!showLoadingSync && !showSyncSuccess && (
            <>
              <p>
                <b>Automated collections</b> rely on Shopify's smart collection
                rules to determine which products are included. If you have
                added <b>new products</b> to your store or{" "}
                <b>updated any product properties</b> (such as tags, prices
                etc.), you may need to manually <u>re-sync your collections</u>{" "}
                to correctly match products to your answers.
              </p>
              <br />
              <p>Click the button below to re-sync your collections.</p>
            </>
          )}
          {showSyncSuccess && !showLoadingSync && (
            <Banner status="success">
              <p>All collections have been successfully re-synced!</p>
            </Banner>
          )}
        </Modal.Section>
      </Modal>
    </Page>
  );
};
