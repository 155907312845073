import { Text } from "@storyofams/react-ui";
import styled from "styled-components";

export const CenteredSubtext = styled(Text).attrs((attrs) => ({
  color: "black60",
  fontSize: 2,
  textAlign: "center",
  lineHeight: "140%",
  ...attrs,
}))``;
