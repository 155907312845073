import { useCallback, useEffect, useState } from "react";

const useCopied = () => {
  const [active, setActive] = useState(false);
  const [id, setId] = useState<null|number>(null);

  const activate = useCallback(()=>setActive(true), [setActive]);

  useEffect(() => {
    if( active ){
      id && clearTimeout(id);
      const timeoutId = window.setTimeout(()=>setActive(false), 2500);
      setId(timeoutId);
    }

    return () => {
      if(id) clearTimeout(id);
    };
  }, [active])

  return ({
    active,
    activate
  });
}

export default useCopied;

