import { useCallback, useState } from "react";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import { Icon, IconSource, Spinner, Stack, TextField } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { Flex } from "@storyofams/react-ui";
import { debounce } from "lodash";

import config from "~/config";
import { UrlFile } from "~/graphql/sdk";

import { MediaModalPaneProps } from "./MediaModal";

export interface GifPaneProps {
  onSelectGif(gif: UrlFile): void;
}

const gf = new GiphyFetch(config.giphyApiKey);

export const GifPane = ({
  onSelectGif,
}: GifPaneProps & MediaModalPaneProps) => {
  const [query, setQuery] = useState("");
  const [params, setParams] = useState({
    query: "",
  });

  const setParamsDebounced = useCallback(
    debounce(setParams, 300, { trailing: true }),
    [setParams]
  );

  const fetchGifs = (offset: number) =>
    params.query
      ? gf.search(params.query, { offset, limit: 10 })
      : gf.trending({ offset, limit: 10 });

  return (
    <Stack vertical>
      <TextField
        autoComplete="off"
        focused
        clearButton
        onChange={(value) => {
          setQuery(value);
          setParamsDebounced({
            query: value,
          });
        }}
        onClearButtonClick={() => {
          setQuery("");
          setParams({
            query: "",
          });
        }}
        value={query}
        label="Search GIFs"
        labelHidden
        placeholder="Search free GIFs"
        prefix={<Icon source={SearchIcon as IconSource} />}
        suffix={
          <img
            alt="Powered by GIPHY"
            src={`${process.env.PUBLIC_URL}/images/powered-by-giphy.png`}
            style={{ width: "120px" }}
          />
        }
      />

      <Flex
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
        css={{
          ".giphy-gif": { cursor: "pointer" },
        }}
      >
        <Grid
          key={params.query}
          width={565}
          columns={3}
          gutter={8}
          fetchGifs={fetchGifs}
          noLink
          onGifClick={(gif) => {
            onSelectGif({
              url: gif.images.original.url,
              width: gif.images.original.width,
              height: gif.images.original.height,
            });
          }}
          borderRadius={8}
          loader={() => (
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="center"
              p="4"
            >
              <Spinner />
            </Flex>
          )}
        />
      </Flex>
    </Stack>
  );
};
