import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Icon,
  IconSource,
  Pagination,
  Stack,
  TextStyle,
  Tooltip,
} from "@shopify/polaris";
import {
  ArrowLeftIcon,
  CheckSmallIcon,
  AlertCircleIcon,
  CheckCircleIcon,
  ViewIcon,
} from "@shopify/polaris-icons";
import { Box, Flex, Text } from "@storyofams/react-ui";
import { AnimatePresence, motion } from "framer-motion";
import qs from "query-string";

import {
  useShopOrigin,
  useFlow,
  // useOffline,
  useSdk,
  IPublishErrors,
} from "~/hooks";
import { useNavParams } from "~/hooks";
import { useToast } from "~/lib";

import { NavButton } from "../sidePanes/EditorPane/NavButton";
import { Link } from "./Link";
import { Nav } from "./Nav";
import { Tabs } from "./Tabs";
import { ContainerQuery } from "~/graphql/sdk";

const MotionFlex = motion(Flex);

let offlineTimer: any = null;

//TODO: move publish model to the end screen
const TABS = [
  "Quiz Builder",
  "Products",
  "More Integrations",
  "Settings",
  "Publish",
];

export const TopBar = ({
  container,
  publishErrorInfo,
}: {
  container: ContainerQuery["flowContainer"] | null;
  publishErrorInfo: IPublishErrors;
}) => {
  const shop = useShopOrigin();
  const { status } = useFlow();
  // const isOffline = useOffline();
  const isOffline = false; // disabling offline checker for now
  const sdk = useSdk();
  const toast = useToast();
  const [isOpeningPreview, setOpeningPreview] = useState(false);
  const [showOffline, setShowOffline] = useState(false);

  const [{ page: selectedTab, main }, setNavParams] = useNavParams();
  const nextTab = useMemo(() => selectedTab + 1, [selectedTab]);
  const nextButtonDisable = useMemo(() => {
    if (nextTab >= TABS.length) {
      return (
        ["error", "saving"].includes(status) ||
        !!publishErrorInfo.publishError ||
        publishErrorInfo.isLoading
      );
    }
    return false;
  }, [nextTab, publishErrorInfo]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 1350);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1150);
      setIsTablet(window.innerWidth <= 1350);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isOffline && offlineTimer) {
      clearTimeout(offlineTimer);
      setShowOffline(true);
    } else if (!isOffline) {
      offlineTimer = setTimeout(() => {
        setShowOffline(false);
      }, 2);
    }
  }, [isOffline]);

  const statusMessage = useMemo(() => {
    switch (status) {
      case "error":
        return (
          <Stack spacing="extraTight">
            <>Error saving changes</>
            <Icon source={AlertCircleIcon as any} color="critical" />
          </Stack>
        );
      case "saving":
        return "Saving...";
      case "saved":
        return (
          <Stack spacing="extraTight">
            <>Saved</>
            <Icon source={CheckSmallIcon as any} color="success" />
          </Stack>
        );
      default:
        return "";
    }
  }, [status]);

  const openPreview = async () => {
    if (isOpeningPreview) {
      return;
    }

    setOpeningPreview(true);

    try {
      let newWindow = window.open("", "_blank");
      const token = await sdk.accessToken().then((res) => res.accessToken);
      if (newWindow) {
        newWindow.location.href = `https://${shop}/tools/${
          process.env.REACT_APP_SHOPIFY_APP_NAME
        }/${container?.slug}?${qs.stringify({
          flowId: container?.flows?.[0]?.id,
          token,
        })}`;
        setOpeningPreview(false);
        return;
      } else {
        toast({
          content: "Please allow popups for this site to see the preview",
          error: true,
        });
      }
      setOpeningPreview(false);
    } catch (e) {
      toast({ content: "Failed to open preview", error: true });
    }
  };

  return (
    <Box position="relative">
      <div
        style={{
          position: "relative",
          height: "3.5rem",
          display: "flex",
          boxShadow: "var(--p-shadow-top-bar)",
          backgroundColor: "var(--p-surface)",
        }}
      >
        <Nav>
          <Box flex="1">
            {main === 0 ? (
              <Stack>
                <Pagination
                  hasPrevious={selectedTab >= 1}
                  onPrevious={() =>
                    selectedTab >= 1 &&
                    setNavParams({ page: `${selectedTab - 1}`, main: "0" })
                  }
                  hasNext={nextTab < TABS.length}
                  onNext={() =>
                    nextTab < TABS.length &&
                    setNavParams({ page: `${nextTab}`, main: "0" })
                  }
                  label={`Step ${selectedTab + 1} of ${TABS.length}`}
                />
              </Stack>
            ) : (
              <Link key={"Back_To_Quiz_Builder"} href={undefined}>
                <Button
                  icon={ArrowLeftIcon as IconSource}
                  onClick={() => setNavParams({ page: "0", main: "0" })}
                />
                <TextStyle variation="subdued">Back to Quiz Builder</TextStyle>
              </Link>
            )}
          </Box>

          {!isMobile && (
            <Tabs
              TABS={TABS}
              selectedTab={selectedTab}
              setNavParams={setNavParams}
            />
          )}

          {!isMobile && (
            <Flex
              flexDirection="column"
              flexWrap="nowrap"
              alignItems="flex-end"
              flex="1"
            >
              <Stack alignment="center" spacing="tight">
                <Text fontSize={1.5} color="subdued">
                  {statusMessage}
                </Text>
                <NavButton
                  icon={ViewIcon}
                  onClick={openPreview}
                  // loading={isOpeningPreview}
                  text="Preview"
                ></NavButton>
                <Tooltip
                  content={
                    nextTab >= TABS.length &&
                    !nextButtonDisable &&
                    "Back to quiz list page"
                  }
                >
                  {nextTab < TABS.length && !isTablet && (
                    <Button
                      onClick={() => {
                        if (nextTab < TABS.length) {
                          setNavParams({ page: `${nextTab}`, main: "0" });
                        }

                        // segment event
                        if (window?.analytics) {
                          window.analytics.track("Flow Builder - Next Button", {
                            nextTab: TABS[nextTab],
                          });
                        }
                      }}
                      url={undefined}
                      primary
                      disabled={nextButtonDisable}
                    >
                      {nextTab >= TABS.length
                        ? "Finish 🎉"
                        : "Next: " + TABS[nextTab]}
                    </Button>
                  )}
                </Tooltip>
              </Stack>
            </Flex>
          )}
        </Nav>
      </div>

      {/* @ts-ignore */}
      <AnimatePresence>
        {showOffline && (
          <MotionFlex
            position="fixed"
            top={0}
            left={0}
            right={0}
            height="40px"
            justifyContent="center"
            alignItems="center"
            bg={isOffline ? "rgba(254, 211, 209, 1)" : "rgba(174, 233, 209, 1)"}
            initial={{ y: "-100%" }}
            animate={{ y: "0%" }}
            exit={{ y: "-100%" }}
            transition={{ ease: "easeOut", delay: isOffline ? 0 : 2 }}
          >
            <Stack alignment="center" spacing="tight">
              <Text>
                You are {isOffline ? "currently offline" : "back online"}.
              </Text>
              <Icon
                source={
                  isOffline
                    ? (AlertCircleIcon as any)
                    : (CheckCircleIcon as any)
                }
                color={isOffline ? "critical" : "success"}
              />
            </Stack>
          </MotionFlex>
        )}
      </AnimatePresence>
    </Box>
  );
};
