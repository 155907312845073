import { Section } from "~/components/Section";
import { Controller } from "react-hook-form";
import { useOptionsForm } from "~/hooks";
import { Checkbox, Select, TextField } from "@shopify/polaris";
import { FlowNodeType } from "~/graphql/sdk";

export const OPTIONS = [
  { label: "Single choice - text only", value: FlowNodeType.Simple },
  { label: "Single choice - with emoji", value: FlowNodeType.Emoji },
  { label: "Single choice - with image", value: FlowNodeType.Image },
  {
    label: "Multi choice - text only",
    value: FlowNodeType.SimpleMulti,
  },
  {
    label: "Multi choice - with emoji",
    value: FlowNodeType.EmojiMulti,
  },
  {
    label: "Multi choice - with image",
    value: FlowNodeType.ImageMulti,
  },
  {
    label: "Input - one line text",
    value: FlowNodeType.InputOneLineText,
  },
  {
    label: "Input - multi line text",
    value: FlowNodeType.InputMultiLineText,
  },
  {
    label: "Transition Screen",
    value: FlowNodeType.Transition,
  },
  // {
  //   label: "Input - calendar",
  //   value: FlowNodeType.InputCalendar,
  // },
  // {
  //   label: "Input - slider",
  //   value: FlowNodeType.InputSlider,
  // },
];

const getDefaultValues = (values) => {
  return {
    type: values?.type || FlowNodeType.InputOneLineText,
    isRequired:
      typeof values?.isRequired !== "undefined" ? !!values.isRequired : false,
    maxLength: values?.maxLength ?? "3",
    inputWidth: values?.inputWidth ?? "",
    inputHeight: values?.inputHeight ?? "",
  };
};

const TransitionOptions = () => {
  const form = useOptionsForm({
    getDefaultValues,
    type: "flowNode",
  });
  const control = form.control;

  return (
    <>
      <Section title="Transition options">
        <Controller
          control={control}
          name="maxLength"
          render={({ field: { ref, ...field } }) => (
            <Select
              label="Auto Transition After:"
              options={[
                { label: "never", value: "0" },
                { label: "1 second", value: "1" },
                { label: "2 seconds", value: "2" },
                { label: "3 seconds", value: "3" },
                { label: "5 seconds", value: "5" },
                { label: "7 seconds", value: "7" },
                { label: "10 seconds", value: "10" },
                { label: "15 seconds", value: "15" },
                { label: "20 seconds", value: "20" },
                { label: "30 seconds", value: "30" },
              ]}
              value={field.value}
              name={field.name}
              onChange={field.onChange}
              helpText="Automatically proceed to the next question after the specified time."
            />
          )}
        />

        <Controller
          control={control}
          name="inputHeight"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Image Height"
              autoComplete="off"
              placeholder="leave blank for auto-sizing"
              value={field.value}
              name={field.name}
              type="text"
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="inputWidth"
          render={({ field: { ref, ...field } }) => (
            <TextField
              label="Image Width"
              autoComplete="off"
              placeholder="leave blank for auto-sizing"
              value={field.value}
              name={field.name}
              type="text"
              onChange={field.onChange}
            />
          )}
        />
      </Section>
      <Section>
        <Controller
          control={control}
          name="isRequired"
          render={({ field: { value, ...field } }) => (
            <Checkbox
              label="Show Next Question Button"
              checked={value}
              {...field}
            />
          )}
        />
      </Section>
    </>
  );
};

export default TransitionOptions;
