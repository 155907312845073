import { forwardRef } from "react";
import { Box, Text, SystemProps } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { rgba } from "polished";
import styled from "styled-components";

interface InputProps extends SystemProps {
  autocomplete?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  name: string;
  placeholder?: string;
  primaryColor?: string;
  className?: string;
  type?: string;
  inputHeight?: string;
}

const Wrapper = styled(Box)`
  width: 100%;
  text-align: start;
`;

const StyledTextarea = styled.textarea<{
  hasError?: boolean;
  primaryColor?: string;
  inputHeight?: string;
}>`
  height: ${(p) => p.inputHeight || "56px"};
  width: 100%;
  font-size: 16px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.black60};
  background: #fff;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${(p) => p.theme.colors.black20};
  padding: 17px 15px;
  outline: none;
  transition: background-color 0.18s ease-in-out, box-shadow 0.18s,
    border-color 0.18s ease-in-out, color 0.18s ease-in-out;

  &::placeholder {
    color: ${(p) => p.theme.colors.black40};
    transition: color 0.18s ease-in-out;
  }

  &:hover:not(:disabled),
  &:focus,
  &:active {
    color: ${(p) => p.theme.colors.black60};
    border-color: ${(p) => p.theme.colors.black60};

    &::placeholder {
      color: ${(p) => p.theme.colors.black60};
    }
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px
      ${(p) => rgba(p.primaryColor || p.theme.colors.primary, 0.4)};
    color: ${(p) => p.theme.colors.black90};
    border-width: 2px;
    padding: 16px 14px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const MultiLineInput = forwardRef(
  ({ inputHeight, error, label, className, ...props }: InputProps, ref) => {
    return (
      <Wrapper {...pick(props)} className={`${className}-wrapper`}>
        {!!label && (
          <Text
            as="label"
            htmlFor={props.name}
            fontSize="12px"
            lineHeight="15px"
            mb={1}
            letterSpacing="-0.01em"
            color="black90"
            display="inline-block"
            verticalAlign="top"
          >
            {label}
          </Text>
        )}

        <StyledTextarea
          ref={ref}
          {...omit(props)}
          id={props.name}
          hasError={!!error}
          className={className}
          aria-invalid={error ? "true" : "false"}
          inputHeight={inputHeight}
        />

        {!!error && (
          <Text
            fontSize="12px"
            lineHeight="15px"
            mt={1}
            letterSpacing="-0.01em"
            color="errorText"
            display="inline-block"
            verticalAlign="top"
            role="alert"
          >
            {error}
          </Text>
        )}
      </Wrapper>
    );
  }
);
