import { Checkbox, RangeSlider, TextField } from "@shopify/polaris";
import { useEffect, useState } from "react";
import useDebounce from "~/hooks/useDebounce";

// modify this component to use useDebounce

export const AnswerWeight = ({ weight, onSave }) => {
  const [w, setW] = useState(weight);
  const debouncedWeight = useDebounce(w, 500);

  const save = async (weight) => {
    await onSave(weight);
  };

  useEffect(() => {
    if (debouncedWeight !== weight) {
      save(debouncedWeight);
    }
  }, [debouncedWeight]);

  if (w === 100) {
    return (
      <Checkbox
        label="Use default answer weight"
        checked={w === 100}
        onChange={() => setW((w) => (w === 100 ? 150 : 100))}
      />
    );
  }

  return (
    <>
      <Checkbox
        label="Use default answer weight"
        checked={w === 100}
        onChange={() => setW((w) => (w === 100 ? 150 : 100))}
      />{" "}
      <RangeSlider
        label="Answer Weight"
        value={w}
        onChange={setW}
        min={0}
        max={10000}
        step={10}
        output
        suffix={
          <TextField
            autoComplete="off"
            label=""
            value={w}
            onChange={(newValue) => setW(parseInt(newValue, 10))}
            type="number"
            min={0}
            max={10000}
            step={10}
            suffix={<span>{w === 1 ? "point" : "points"}</span>}
          />
        }
      />
    </>
  );
};
