import { Stack, Icon, Link, TextStyle, IconSource } from "@shopify/polaris";
import { InfoIcon } from "@shopify/polaris-icons";
import { Flex } from "@storyofams/react-ui";
import { useEffect, useState } from "react";

import {
  MainPane,
  LeftSideMenu,
  RightSideMenu,
  SIDE_MENU_WIDTH,
} from "~/components";
import { useFlow, useIsRtl, useNavParams } from "~/hooks";

export const QuestionsPage = () => {
  const { data } = useFlow();
  const [{ main }] = useNavParams();
  const isRtl = useIsRtl();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!data?.flows?.[0]) {
    return null;
  }

  const desktopStyles = {
    "margin-left": `calc(${SIDE_MENU_WIDTH} + 1rem)`,
    "margin-right": `calc(${SIDE_MENU_WIDTH} + 1rem)`,
    "overflow-x": "hidden",
    "> div:first-child, .Polaris-Tabs__Panel:not(.Polaris-Tabs__Panel--hidden)":
      {
        display: "flex",
        flexDirection: "column",
        flex: "1",
      },
  };

  const mobileStyles = {
    width: "100%",
    "> div:first-child, .Polaris-Tabs__Panel:not(.Polaris-Tabs__Panel--hidden)":
      {
        display: "flex",
        flexDirection: "column",
        flex: "1",
      },
  };

  return (
    <Flex
      flexDirection={isMobile ? "column" : "row"}
      flex="1"
      height="100%"
      width="100%"
      css={{
        "background-color": "#fff",
      }}
    >
      <LeftSideMenu />

      <Flex
        flexDirection="column"
        flex="1"
        mx={2}
        css={isMobile ? mobileStyles : desktopStyles}
      >
        <MainPane isRtl={isRtl} tab={main} flow={data.flows[0]} />

        <Flex pt={1} pb={3} alignItems="center" justifyContent="center">
          <Stack spacing="tight">
            <Icon source={InfoIcon as IconSource} color="highlight" />
            <TextStyle variation="subdued">
              Learn more about{" "}
              <Link
                external
                url="https://www.trylantern.com/docs/l/create-your-first-quiz"
              >
                building your first quiz
              </Link>
            </TextStyle>
          </Stack>
        </Flex>
      </Flex>

      <RightSideMenu />
    </Flex>
  );
};
