import { ReactNode } from 'react';
import { Flex, SystemProps } from '@storyofams/react-ui';

interface SideMenuProps extends SystemProps {
  children?: ReactNode;
  topStyles?: {[key: string]: string }
}

export const SIDE_MENU_WIDTH = '262px';

export const SideMenu = ({ children, topStyles, ...props }: SideMenuProps) => (
  <Flex
    width={SIDE_MENU_WIDTH}
    minWidth={SIDE_MENU_WIDTH}
    height="100%"
    position="relative"
    {...topStyles}
  >
    <Flex
      width="100%"
      flexDirection="column"
      bg="white"
      position="absolute"
      top={0}
      right={0}
      left={0}
      bottom={0}
      overflowY="auto"
      overflowX="hidden"
      {...props}
    >
      {children}
    </Flex>
  </Flex>
);
