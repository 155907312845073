import { GraphQLClient } from "graphql-request";

import config from "~/config";
import { getSdk as getGraphqlSdk } from "~/graphql/sdk";

export const getClient = (fetch?: any) =>
  new GraphQLClient(config.graphqlUrl, {
    fetch,
    credentials: "include",
    headers: {
      "X-PPF-IFRAME": "embedded",
    },
  });

export const getSdk = (fetch?: any) => getGraphqlSdk(getClient(fetch));
