import React from "react";
import { Modal } from "@shopify/polaris";
import {
  EmailNewsletterIcon,
  ExchangeIcon,
  PaintBrushFlatIcon,
  PlusCircleIcon,
  PersonIcon,
  FormsIcon,
} from "@shopify/polaris-icons";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import DescriptionCard from "~/components/BillingAlertModal/DescriptionCard";

const ModalWrapper = styled.div`
  [class~="Polaris-Modal-Section"] {
    padding-bottom: 0;
  }
`;

const CardLine = styled.div`
  margin-bottom: 1.8rem;
  display: flex;

  [class~="Polaris-Card"] + [class~="Polaris-Card"] {
    margin-top: 0;
    margin-left: 1.8rem;
  }

  & > * {
    flex-basis: 50%;
  }
`;

const PurpleCardLine = styled(CardLine)`
  & * {
    fill: #6138fe;
  }
`;

interface BillingModalProps {
  open: boolean;
  setClose: () => void;
  isInitial: boolean;
  setIsInitial: React.Dispatch<boolean>;
}

const BillingModal: React.FC<BillingModalProps> = ({
  open,
  setClose,
  isInitial,
  setIsInitial,
}) => {
  const history = useHistory();
  const location = useLocation();

  const onClose = () => {
    if (isInitial) {
      const homeUrl =
        location.pathname + location.search.replace("main=1", "main=0");

      setIsInitial(false);
      history.push(homeUrl);
    }
    setClose();
  };

  const closeOnAction = () => {
    isInitial && setIsInitial(false);
    setClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        "Unlock Unlimited Questions, Transition Screens, User Input Pages, Email, CSS, Logic Jumps, Content Blocks & More!"
      }
      primaryAction={{
        content: "Start your 14-day free trial",
        onAction() {
          window?.top?.frames?.["app-iframe"]?.postMessage(
            {
              action: "openBilling",
            },
            "*"
          );
          closeOnAction();
        },
      }}
    >
      <ModalWrapper>
        <Modal.Section>
          <PurpleCardLine>
            <DescriptionCard
              icon={EmailNewsletterIcon}
              title={"Email Results"}
              content={
                "Segment customers and send product recommendation results via email."
              }
            />
            <DescriptionCard
              icon={PaintBrushFlatIcon}
              title={"CSS Editing"}
              content={
                "Fully customize your quiz to perfectly match your store design and theme using the full CSS editor."
              }
            />
          </PurpleCardLine>
          <PurpleCardLine>
            <DescriptionCard
              icon={ExchangeIcon}
              title={"Logic Jumps"}
              content={"Use conditional logic to create more complex quizzes."}
            />
            <DescriptionCard
              icon={PersonIcon}
              title={"Dynamic Content Blocks"}
              content={
                "Display more than just product recommendations - show a fully custom results page based on each customer's answers."
              }
            />
          </PurpleCardLine>
          <PurpleCardLine>
            <DescriptionCard
              icon={FormsIcon}
              title={"User Input"}
              content={
                "Go beyond multiple choice questions and allow customers to type in their own answers."
              }
            />
            <DescriptionCard
              icon={PlusCircleIcon}
              title={"And Much, Much More!"}
              content={
                "Integrate reviews, multi-choice questions, build complex quizzes with unlimited questions, use popups, embeds and more."
              }
            />
          </PurpleCardLine>
        </Modal.Section>
      </ModalWrapper>
    </Modal>
  );
};

export default BillingModal;
